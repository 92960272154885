import React from 'react';
import './login.styles.scss';
import { connect } from 'react-redux';
import { setSessionToken } from '../../store/actions';
import Icon from '../../components/icon/icon.component';
import lopLogo from '../../assets/img/lop-logo-white@3x.png';
import cambridgeLogo from '../../assets/img/cambridge-logo-white@3x.png';
import peacemakerLogo from '../../assets/img/peacemaker-logo-white@3x.png';
import AuthenticationForm from '../../components/authentication-form/authentication-form.component';
import { useNavigate } from 'react-router';

const Login: React.FC = () => {
    const navigate = useNavigate();

    return (
        <div className="lop-login-page">
            <div className="container-fluid">
                <div className="row lop-login-header">
                    <div className="col">
                        <Icon src={ lopLogo } x3/>
                    </div>
                    <div className="col-auto">
                        <Icon src={ cambridgeLogo } x3/>
                        <Icon src={ peacemakerLogo } x3/>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <AuthenticationForm onLogin={ (token: string) => {} }/>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default connect(null, { setSessionToken })(Login)