import React, { FormEvent } from 'react';
import CSS from 'csstype';
import { connect } from 'react-redux';
import {
    SortableContainer,
    SortableElement,
    SortableHandle
} from 'react-sortable-hoc';

import './edit-tags.styles.scss';
import iconDraggable from './icon-draggable.png';

import TopBar from '../../components/topbar/topbar.component';
import Content from '../../components/content/content.component';
import MessageBar from '../../components/message-bar/message-bar.component';
import { deleteTags, getTags, updateTags } from '../../api.v3';

type MessageType = 'message' | 'success' | 'danger' | 'error';

interface EditTagsProps {
    tagList: any[]
}

interface EditTagsState {
    message: string | null,
    messageType: MessageType,
    tagFields: TagField[],
    pristineTagFields: TagField[],
    childSpacing: number,
    focusedIndex: number,
    readonly: boolean
}

interface TagField {
    id: number,
    name: string,
    parentTagId: number | null,
    weight: number,
    level: number,
    deleted: boolean
}

interface TagEdition {
    id?: number,
    name?: string,
    parentTagId?: number | null,
    weight?: number
}

const initialState: EditTagsState = {
    message: null,
    messageType: 'message',
    tagFields: [],
    pristineTagFields: [],
    childSpacing: 30,
    focusedIndex: -1,
    readonly: false
}

const mapStateToProps = (state: any) => {
    return {
        tagList: state.tagList.data
    }
}

const EditableTags: React.FC<{children: React.ReactNode}> = ({ children }) =>
    <ul className="lop-sortable-tags">{ children }</ul>

const EditableTag: React.FC<{children: React.ReactNode}> = ({ children }) =>
    <li className="lop-sortable-tag">{ children }</li>

const EditableTagHandle: React.FC = () =>
    <div className="lop-sortable-tag-handle"><img src={ iconDraggable } height="20"/></div>

const SortableTags = SortableContainer(EditableTags) as any;
const SortableTag = SortableElement(EditableTag) as any;
const SortableTagHandle = SortableHandle(EditableTagHandle) as any;


class EditTags extends React.PureComponent<EditTagsProps, EditTagsState> {
    constructor(props: EditTagsProps) {
        super(props);

        this.state = {
            ...initialState,
            ...this.buildFields(props.tagList)
        };
    }

    private dragXStart: number = 0;
    private dragLevelStart: number = 0;
    private dragCurrentLevel: number = -1;
    private dragGuideElement: any | null = null;
    private newTagIdCount: number = 0;

    private addNewTag(): void {
        const tagsElement: HTMLUListElement | null = document.querySelector('ul.lop-sortable-tags');
        const tagElements: HTMLCollectionOf<Element> = document.getElementsByClassName('editable-tag');

        const scroll = tagsElement ? (
            tagsElement.offsetTop
        ) : 0;

        for (let i = 0; i < tagElements.length; i++) {
            if ((tagElements[i].getBoundingClientRect().top - scroll + 60) > 0) {
                this.newTagIdCount--;

                const index: number = Number(tagElements[i].getAttribute('data-index')) || 0;
                const movedField = this.state.tagFields[index];
                const newTagFields = [...this.state.tagFields];
                const newField: TagField = {
                    id: this.newTagIdCount,
                    name: '',
                    parentTagId: movedField.parentTagId,
                    weight: movedField.weight - 1,
                    deleted: false,
                    level: movedField.level
                };

                newTagFields.splice(index, 0, newField);

                this.correctParentTagsLevelsAndWeights(newTagFields);

                this.setState({
                    tagFields: newTagFields,
                    focusedIndex: index
                });

                setTimeout(() => {
                    const input: HTMLInputElement | null = document.querySelector(`input[data-index="${index}"]`);

                    if (input) input.focus();
                }, 10)

                break;
            }
        }
    }

    private removeTag(index: number): void {
        const newTagFields: TagField[] = [...this.state.tagFields];

        if (this.state.tagFields[index].id > 0) {
            newTagFields[index].deleted = true;
        }
        else {
            newTagFields.splice(index, 1);
        }

        this.correctParentTagsLevelsAndWeights(newTagFields);

        this.setState({
            tagFields: newTagFields
        })
    }

    private restoreTag(index: number): void {
        const tagToRestore = this.state.tagFields[index];

        if (tagToRestore && tagToRestore.deleted) {

            const newTagFields = [...this.state.tagFields];
            newTagFields[index].deleted = false;

            this.correctParentTagsLevelsAndWeights(newTagFields);
    
            this.setState({
                tagFields: newTagFields
            });
        }
    }

    private buildFields(tagList: any[]): {tagFields: TagField[], pristineTagFields: TagField[]} {
        const tagFields: TagField[] = this.getTagFields(tagList);
        const pristineTagFields: TagField[] = JSON.parse(JSON.stringify(tagFields));
        return { tagFields, pristineTagFields };
    }

    private getTagFields(tagList: any[]): TagField[] {
        const result: TagField[] = [];

        const addListToResult = (list: any[], level: number) => {
            list.forEach(tagData => {
                result.push({
                    id: tagData.id || null,
                    name: tagData.name || '',
                    parentTagId: tagData.parentTagId || null,
                    weight: tagData.weight,
                    level,
                    deleted: false
                })

                if (tagData.childrenTags && tagData.childrenTags.length) {
                    addListToResult(tagData.childrenTags, level + 1);
                }
            })
        }

        addListToResult(tagList, 0);

        return result;
    }

    private getEditableTagStyles(tagField: TagField, index: number): CSS.Properties {
        return {
            marginLeft: (tagField.level * this.state.childSpacing) + 'px',
            boxShadow: index === this.state.focusedIndex ? '2px 3px 13px 0 #dfe4ec' : 'none'
        }
    }

    private getEditableTagClassName(tagField: TagField, index: number): string {
        const originalField: TagField | null = tagField.id > 0 ? this.getOriginalValues(tagField) : null;

        return (
            'editable-tag'
            + (originalField && this.hasChanges(originalField, tagField) ? ' lop-changed' : '')
            + ((tagField.id < 0) ? ' lop-new' : '')
            + (tagField.deleted ? ' lop-removed' : '')
            + (index === this.state.focusedIndex ? ' lop-focused' : '')
        )
    }
    
    private getOriginalValues(tagField: TagField): TagField {
        return this.state.pristineTagFields.filter(pristineTag => tagField.id === pristineTag.id)[0];
    }

    private getChanges(): TagEdition[] {
        const result: TagEdition[] = [];

        this.state.tagFields.forEach(field => {
            if ((field.id > 0) && !field.deleted) {
                const originalField = this.getOriginalValues(field);

                if (this.hasChanges(originalField, field)) {
                    const editedField: TagEdition = {
                        id: field.id
                    };

                    if (originalField.name != field.name) editedField.name = field.name;
                    if (originalField.parentTagId != field.parentTagId) editedField.parentTagId = field.parentTagId || null;
                    if (originalField.weight != field.weight) editedField.weight = field.weight;
    
                    result.push(editedField);
                }
            }
        })

        return result;
    }

    private getCreations(): TagEdition[] {
        const result: TagEdition[] = [];

        this.state.tagFields.forEach(field => field.id < 0
            ? result.push({
                id: field.id,
                name: field.name,
                parentTagId: field.parentTagId || null,
                weight: field.weight
            })
            : undefined
        )

        return result;
    }

    private getDeletions(): number[] {
        const result: number[] = [];

        this.state.tagFields.forEach(field => field.deleted && field.id
            ? result.push(field.id)
            : undefined
        )

        return result;
    }

    private getPreviousTagField(tagFields: TagField[], index: number): TagField | null {
        let currentIndex: number = index - 1;
        let currentTagField: TagField | null = tagFields[currentIndex] || null;

        while (currentTagField && currentTagField.deleted) {
            currentTagField = tagFields[currentIndex] || null;
            currentIndex--;
        }

        return currentTagField;
    }

    private getPreviousSiblingTagField(tagFields: TagField[], index: number): TagField | null {
        for (let i = index - 1; i > -1; i--) {
            if (tagFields[i] && !tagFields[i].deleted && (tagFields[i].parentTagId === tagFields[index].parentTagId)) {
                return tagFields[i];
            }
        }

        return null;
    }

    private hasChanges(originalField: TagField, field: TagField): boolean {
        return (
            // this.state.pristineTagFields[index].id !== field.id
            originalField.name !== field.name
            || originalField.parentTagId !== field.parentTagId
            || originalField.weight !== field.weight
            || originalField.deleted !== field.deleted
        )
    }

    private hasChangesAll(): boolean {
        for (const field of this.state.tagFields) {
            if (field.id < 0) {
                return true;
            }

            const originalField = this.getOriginalValues(field);
            
            if (this.hasChanges(originalField, field)) {
                return true;
            }
        }

        return false;
    }

    /**
     * Function to transform and obtain 100% trust data from indexes (array index) and levels ("level" value).
     * Set min and max levels to correct "parentTagId" and "weight" values.
     * It is possible to create new features inside this function.
     */
    private correctParentTagsLevelsAndWeights(tagFields: TagField[]): void {
        tagFields.forEach((currentTagField: TagField, index: number) => {
            // "parentTagId" and "level" values correction
            const previousTagField: TagField | null = this.getPreviousTagField(tagFields, index);

            if (!previousTagField || currentTagField.level <= 0) { // is the first tagField or level is 0 or minor
                currentTagField.level = 0; // set level
                currentTagField.parentTagId = null; // correcting parentTagId
            }
            else {
                currentTagField.level = Math.max(currentTagField.level, 0); // set min level

                if (currentTagField.level === previousTagField.level) { // previous is sibling
                    currentTagField.parentTagId = previousTagField.parentTagId; // correcting parentTagId
                }
                else if (currentTagField.level > previousTagField.level) { // previous is parent
                    currentTagField.level = Math.max(previousTagField.level + 1, 0); // set max level
                    currentTagField.parentTagId = previousTagField.id;  // correcting parentTagId
                }
                else { // previous is child of another parent
                    for (let i = index - 1; i > -1; i--) { // search in all previous tagFields
                        if (tagFields[i].level === currentTagField.level) { // previous tagField is sibling
                            currentTagField.level = Math.max(tagFields[i].level, 0); // set max level from previous sibling
                            currentTagField.parentTagId = tagFields[i].parentTagId; // correcting parentTagId from previous sibling
                            break;
                        }
                    }
                }
            }

            // "weight" value correction
            const previousSibling: TagField | null = this.getPreviousSiblingTagField(tagFields, index); // getting previous sibling
            currentTagField.weight = previousSibling ? previousSibling.weight + 1 : 0; // correcting weight from previous sibling
        });
    }

    private handleChangeName(event: FormEvent<HTMLInputElement>, index: number): void {
        this.state.tagFields[index].name = event.currentTarget.value;

        this.setState({
            tagFields: [...this.state.tagFields]
        })
    }

    private handleSortStart(event: any, index: number): void {
        event.preventDefault();
        
        this.dragXStart = event.pageX;
        this.dragLevelStart = this.state.tagFields[index].level;
        this.dragGuideElement = document.querySelector('body > li.lop-sortable-tag') || null;
        this.dragGuideElement.querySelector('.editable-tag').style.boxShadow = '2px 3px 13px 0 #dfe4ec';

        this.setState({ focusedIndex: index })
    }

    private handleSortMove(event: any): void {
        const dragXCurrent = event.pageX;
        const dragXMovement = dragXCurrent - this.dragXStart;
        const dragXSteps = Math.round(dragXMovement / this.state.childSpacing);
        const newLevel = Math.max(this.dragLevelStart + dragXSteps, 0);

        if (newLevel !== this.dragCurrentLevel) {
            this.dragCurrentLevel = newLevel;
            this.dragGuideElement.style.marginLeft = ((newLevel - this.dragLevelStart) * this.state.childSpacing) + 'px';
        }
    }

    private handleSortEnd(oldIndex: number, newIndex: number): void {
        const sortedField = this.state.tagFields[oldIndex];

        if ((oldIndex !== newIndex) || (sortedField.level !== this.dragCurrentLevel)) {
            const newTagFields = [...this.state.tagFields];

            newTagFields.splice(newIndex, 0, newTagFields.splice(oldIndex, 1)[0]);
            newTagFields[newIndex].level = this.dragCurrentLevel;
    
            this.correctParentTagsLevelsAndWeights(newTagFields);
    
            this.setState({
                tagFields: newTagFields,
                focusedIndex: newIndex
            });
        }

        this.dragXStart = -1;
        this.dragLevelStart = -1;
        this.dragGuideElement = null;
        this.dragCurrentLevel = -1;
    }

    private showMessage(message: string | null, messageType: MessageType = 'message', timeout: number = 0) {
        this.setState({
            message,
            messageType
        });

        if (timeout > 0) {
            setTimeout(() => {
                this.setState({
                    message: null,
                    messageType: 'message'
                });
            }, timeout)
        }
    }

    private handleSubmit(): void {
        
        const updateAndCreateTagsAction: any = (next: any) => {
            const updatesAndCreations = this.getCreations().concat(this.getChanges());
            
            if (updatesAndCreations.length)
                updateTags(updatesAndCreations).then(
                    response => {
                        next(response);
                    },
                    err => {
                        // console.log({err});

                        err.status === 401
                            ? this.showMessage('You are not authorized to make this request', 'danger', 8000)
                            : this.showMessage('Error updating caches', 'error', 8000)
                    }
                );
            else next();
        }

        const deleteTagsAction: any = (next: any) => {
            const deletions = this.getDeletions();

            if (deletions.length)
                deleteTags(deletions).then(
                    response => {
                        next();
                    },
                    err => err.status === 401
                        ? this.showMessage('You are not authorized to make this request', 'danger', 8000)
                        : this.showMessage('Error updating caches', 'error', 8000)
                );
            else next();
        };

        const onFinish: any = (result: any) => {
            this.showMessage('Tags saved successfully. Updating list', 'success', 8000)

            getTags().then(
                () =>  this.setState({
                        ...this.buildFields(this.props.tagList)
                    })
            );


        };

        updateAndCreateTagsAction(
            () => deleteTagsAction(
                () => onFinish()
            )
        );
    }

    public render() {
        const { message, tagFields, messageType } = this.state;

        return (
            <>
                <TopBar/>
                <div className="lop-control-bar">
                    <div className="lop-control-bar-fixed-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-6">
                                    <h2 className="lop-edit-tags-title">Edit tags</h2>
                                </div>
                                <div className="col-6">
                                    <div className="lop-edit-tags-buttons">
                                        <button
                                        type="button"
                                        className="lop-btn"
                                        onClick={ e => this.addNewTag() }>
                                            Add new tag
                                        </button>
                                        <button
                                        type="button"
                                        className="lop-btn lop-btn-dark"
                                        disabled={ !this.hasChangesAll() }
                                        onClick={ e => this.handleSubmit() }>
                                            Apply
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <MessageBar message={ message } type={ messageType }/>
                <Content>
                    <div className="container">
                        <SortableTags
                        lockAxis="y"
                        useDragHandle={ true }
                        onSortStart={ ({node, index, collection, isKeySorting}: any, event: any) => this.handleSortStart(event, index) }
                        onSortMove={ (event: any) => this.handleSortMove(event) }
                        onSortEnd={ ({oldIndex, newIndex, collection, isKeySorting}: any, event: any) => this.handleSortEnd(oldIndex, newIndex) }>
                            { tagFields.map((tagField, index) => (
                                <SortableTag index={ index } key={ index }>
                                    <div
                                    className={ this.getEditableTagClassName(tagField, index) }
                                    data-index={ index }
                                    style={ this.getEditableTagStyles(tagField, index) }
                                    onMouseDown={ e => this.setState({ focusedIndex: index }) }
                                    onDoubleClick={ e => { this.restoreTag(index); } }>
                                        <SortableTagHandle/>
                                        <div className="lop-editable-tag-actions">
                                            {/* <strong style={ {marginLeft: '20px', color: 'red'} }>id { tagField.id }</strong>
                                            <span style={ {marginLeft: '20px'} }>weight { tagField.weight }</span>
                                            <span style={ {marginLeft: '20px'} }>parent { tagField.parentTagId }</span> */}
                                            <button
                                            type="button"
                                            tabIndex={ index + 2 }
                                            onClick={ e => { e.stopPropagation(); this.removeTag(index) } }
                                            onFocus={ e => this.setState({ focusedIndex: index }) }>
                                                &times;
                                            </button>
                                        </div>
                                        <div className="editable-tag-text">
                                            <input type="text" className="lop-input"
                                            data-index={ index }
                                            tabIndex={ index + 1 }
                                            value={ tagField.name }
                                            onChange={ e => this.handleChangeName(e, index) }
                                            onFocus={ e => this.setState({ focusedIndex: index }) }/>
                                        </div>
                                    </div>
                                </SortableTag>
                            )) }
                        </SortableTags>
                    </div>
                </Content>
            </>
        )
    }
}

export default connect(mapStateToProps, {})(EditTags);