import React from 'react';
import { Link, Navigate } from 'react-router-dom';

import { connect } from 'react-redux';
import { Checkbox, Radio } from '../../components/fields';
import ButtonPlus from '../../components/button-plus/button-plus.component';
import CaracterCounter from '../../components/character-counter/character-counter.component';
import ParagraphList from '../../components/paragraph-list/paragraph-list.component';
import SigneeList from '../../components/signee-list/signee-list.component';
import MessageBar from '../../components/message-bar/message-bar.component';
import TopBar from '../../components/topbar/topbar.component';
import {
    setSelectedAgreement,
    removeSelectedAgreement
} from '../../store/selected-agreement/selected-agreement.actions';
import { clearAgreementData } from '../../store/actions';
import './edit.styles.scss';
import Multitext from '../../components/multitext/multitext.component';
import DebugInfo from '../../components/debug-info-list/debug-info-list.component';
import {
    getAgreement,
    updateAgreement,
    createAgreement,
    getAgreementList,
    getMetadata,
    uploadPdfFile,
    getEndpoint,
    deleteParagraphs
} from '../../api.v3';
import Spinner from '../../components/spinner/spinner.component';
import Section, { SectionDesign } from '../../components/section/section.component';
import Content from '../../components/content/content.component';

interface EditProps {
    setSelectedAgreement?: typeof setSelectedAgreement,
    removeSelectedAgreement?: typeof removeSelectedAgreement,
    clearAgreementData?: typeof clearAgreementData,
    agreement?: any,
    languageList?: any[],
    conflictTypeList?: any[],
    regionList?: any[],
    statusList?: any[],
    paragraphList?: any[],
    agreementList?: any[],
    translationList?: any[],
    locationOfConflictList?: any[],
    typeOfInstrumentList?: any[]
}

type MessageType = 'message' | 'success' | 'danger' | 'error';

interface EditState {
    isNew: boolean,
    agreement: any,
    messageType: MessageType,
    savedSuccessfullyMessage: string | null,
    saving: boolean,
    duplicatedIds: number[]
}

const initialState: EditState = {
    isNew: false,
    agreement: null,
    messageType: 'message',
    savedSuccessfullyMessage: null,
    saving: false,
    duplicatedIds: []
};

const monthNames: string[] = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

const mapStateToProps = (state: any) => {
    return {
        languageList: state.languageList.data,
        conflictTypeList: state.conflictTypeList.data,
        regionList: state.regionList.data,
        statusList: state.statusList.data,
        agreement: state.agreement.data,
        paragraphList: state.paragraphList.data,
        agreementList: state.agreementList.data,
        translationList: state.translationList.data,
        locationOfConflictList: state.locationOfConflictList.data,
        typeOfInstrumentList: state.typeOfInstrumentList.data
    }
}

const mapDispatchToProps = {
    setSelectedAgreement,
    removeSelectedAgreement
};

const setObjectValue = (object: any, objectKeyRoute: string, value: any): any => {
    let keys: any = objectKeyRoute.split('.')
    let currentKey: any = keys.shift()
    let currentObj = object

    while (keys.length) {
        if (typeof currentObj[currentKey] !== 'object')
            currentObj[currentKey] = {}

        currentObj = currentObj[currentKey]
        currentKey = keys.shift()
    }


    currentObj[currentKey] = value

    return object
};

const getIdFromRoute = (): number | null => {
    const urlParts = window.location.href.split('/');
    const id = Number(urlParts.pop());
    const pageName = urlParts.pop();

    if (!isNaN(id) && (pageName === 'agreement')) {
        return id;
    }

    return null;
};

class Edit extends React.Component<EditProps, EditState> {
    public state: EditState = {
        ...initialState
    }
    private redirectId: any;
    private editions: any = {
        agreement: {}
    }
    private saving: boolean = false;
    private duplicatedIdsToDelete: number[] = [];
    private idFromParam: number | null = null;

    componentDidMount() {
        this.idFromParam = getIdFromRoute();
        const id = this.idFromParam;

        if (id) {
            this.fetchAgreementFullData(id);
        }
        else {
            this.editions = {
                agreement: {}
            };

            this.setState({
                isNew: true,
                agreement: {
                    pdfUrl: '',
                    createdAt: '',
                    date: '',
                    id: null,
                    metadata: [],
                    metadataIds: [],
                    paragraphs: [],
                    published: false,
                    relatedAgreements: [],
                    relatedAgreementIds: [],
                    signees: [],
                    title: '',
                    updatedAt: '',
                    pdfFiles: []
                }
            })
        }
    }

    onRouteUpdate() {
        this.componentDidMount();
    }

    private fetchAgreementFullData(id: number): void {
        getAgreement(id).then(
            agreement => {
                this.setState({
                    agreement,
                    isNew: false,
                    duplicatedIds: this.getDuplicatedParagraphIds(agreement)
                });
            },
            error => {}
        );
    }

    private getDuplicatedParagraphIds(agreement: any): number[] {
        if (agreement && Array.isArray(agreement.paragraphs)) {
            let previousParagraphText: string | null = null;
            let previousParagraphLevel: number | null = null;
            let previousParagraphTagIds: string | null = null;

            return agreement.paragraphs
                .sort((a: any, b: any) => a.order - b.order)
                .filter((paragraph: any) => {
                    const isDuplicated = (
                        paragraph.text === previousParagraphText
                        && paragraph.level === previousParagraphLevel
                        && paragraph.tagIds.join(',') === previousParagraphTagIds
                    );

                    previousParagraphText = paragraph.text;
                    previousParagraphLevel = paragraph.level;
                    previousParagraphTagIds = paragraph.tagIds.join(',');

                    return isDuplicated;
                })
                .map((paragraph: any) => paragraph.id);
        }

        return [];
    }

    private edit(location: string, value: any): void {
        setObjectValue(
            this.editions, location, value
        )
        this.setState(
            setObjectValue(
                this.state, location, value
            )
        )
    }
    
    private editUniqueMetadata(selectedId: number | string, selectedList: any[]): void {
        const { metadataIds } = this.state.agreement
        const containedId = this.thisContainsOneOfThat(
            metadataIds,
            selectedList.map(t => t.id)
        )

        this.edit('agreement.metadataIds', containedId ? 
            metadataIds.map((code: any) => code === containedId ? Number(selectedId) : code)
            :
            [...metadataIds, Number(selectedId)])
    }

    private hasSameValues(dict1: any, dict2: any): boolean {
        const keys1 = Object.keys(dict1)
        const keys2 = Object.keys(dict2)
        let currentKey: any;
        let currentValueIn1: any;
        let currentValueIn2: any;

        if (keys1.length !== keys2.length)
            return false

        for (let i = 0; i < keys1.length; i++) {
            currentKey = keys1[i]
            currentValueIn1 = dict1[currentKey]
            currentValueIn2 = dict2[currentKey]

            if (currentValueIn1 !== currentValueIn2)
                return false;
        }

        return true;
    }

    private thisContainsOneOfThat(thisContainsOne: any[], ofThat: any[]): any {
        for (let i = 0; i < ofThat.length; i++) {
            if (thisContainsOne.indexOf(ofThat[i]) !== -1)
                return ofThat[i]
        }
    }

    private thisContainsManyOfThat(thisContainsMany: any[], ofThis: any[]): any[] {
        return thisContainsMany.filter(data => ofThis.indexOf(data) > -1)
    }

    private equalValueOrCollection(valueOrCollection1: any, valueOrCollection2: any): boolean {
        if (Array.isArray(valueOrCollection1)) {
            valueOrCollection1 = valueOrCollection1.filter(v => v !== undefined)
            valueOrCollection2 = valueOrCollection2.filter((v: any) => v !== undefined)

            if (valueOrCollection1.length !== valueOrCollection2.length)
                return false

            for (let i = 0; i < valueOrCollection2.length; i++)
                if (valueOrCollection1.indexOf(valueOrCollection2[i]) === -1)
                    return false
            
            return true
        }

        return valueOrCollection1 === valueOrCollection2
    }

    private getFilteredChangesFromAgreement(): any {
        const agreementChanges: any = {}
        let signeeListChanges: any[] = []
        let paragraphListChanges: any[] = []
        let pdfFileListChanges: any[] = []

        Object.keys(this.editions.agreement).forEach(agreementEditionKey => {
            // check each key
            if (!this.equalValueOrCollection(this.props.agreement[agreementEditionKey], this.editions.agreement[agreementEditionKey])) {
                agreementChanges[agreementEditionKey] = this.editions.agreement[agreementEditionKey]
            }

            // check signees
            if ((agreementEditionKey === 'signees')) {
                const signeeChanges = this.getFilteredCollectionChanges(
                    this.props.agreement.signees,
                    this.editions.agreement.signees,
                    ['name', 'position', 'metadataId', 'typeCode'],
                    (resultChanges: any, edited: any, original: any) => {
                        if (!resultChanges.DELETE && resultChanges.id && edited.metadataId && (edited.metadataId.constructor.name === 'String'))
                            resultChanges.typeCode = edited.typeCode || original.typeCode

                        return resultChanges
                    }
                )

                if (signeeChanges.length)
                    signeeListChanges = signeeChanges
            }

            // check paragraphs
            else if ((agreementEditionKey === 'paragraphs')) {
                const paragraphChanges = this.getFilteredCollectionChanges(
                    this.props.agreement.paragraphs,
                    this.editions.agreement.paragraphs,
                    ['level', 'order', 'text', 'tagIds']
                )

                if (paragraphChanges.length)
                    paragraphListChanges = paragraphChanges
            }

            // check pdfs
            else if (agreementEditionKey === 'pdfFiles') {
                console.log(this.props)
                const pdfFileChanges = this.getFilteredCollectionChanges(
                    this.props.agreement.pdfFiles,
                    this.editions.agreement.pdfFiles,
                    ['metadataId']
                )

                if (pdfFileChanges.length)
                    pdfFileListChanges = pdfFileChanges
            }

            // check simple fields
            else if (!String(agreementChanges[agreementEditionKey]).trim().length) {
                agreementChanges[agreementEditionKey] = null
            }
        })

        if (signeeListChanges.length)
            agreementChanges.signees = signeeListChanges
        else delete agreementChanges.signees

        if (paragraphListChanges.length)
            agreementChanges.paragraphs = paragraphListChanges
        else delete agreementChanges.paragraphs

        if (pdfFileListChanges.length)
            agreementChanges.pdfFiles = pdfFileListChanges
        else delete agreementChanges.pdfFiles

        return agreementChanges
    }

    private getFilteredCollectionChanges(originalCollection: any, editedCollection: any, includedKeys: any, afterEachEvalFn?: any): any {
        const changes: any[] = []
        const typeFormatCorrection = (value: any) => {
            if (typeof value === 'string') {
                value = value.trim()
                
                if (value === '')
                    return null

                return value.trim()
            }

            return value
        }
        const areDifferent = (value1: any, value2: any) => {
            if (Array.isArray(value1) || Array.isArray(value1)) {
                if (!Array.isArray(value1) || !Array.isArray(value1))
                    return false
                
                return !this.equalValueOrCollection(value1, value2)
            }

            return typeFormatCorrection(value1) !== typeFormatCorrection(value2)
        }

        // check new and edited items
        editedCollection.forEach((editedItem: any) => {
            let keysToCheck = includedKeys || Object.keys(editedItem)


            if (editedItem.id) {
                // check item editions
                const originalItem = originalCollection.filter((originalItem: any) => originalItem.id === editedItem.id)[0]
                const itemChanges: any = {}
                Object.keys(editedItem).filter(itemKey => keysToCheck.indexOf(itemKey) !== -1).forEach(itemKey => {
                    if (areDifferent(editedItem[itemKey], originalItem[itemKey]))
                        itemChanges[itemKey] = editedItem[itemKey]
                })
                if (Object.keys(itemChanges).length) {
                    itemChanges.id = editedItem.id
                    changes.push(afterEachEvalFn ? afterEachEvalFn(itemChanges, editedItem, originalItem) : itemChanges)
                }
            }
            else
                // add new item
                changes.push(afterEachEvalFn ? afterEachEvalFn(editedItem, editedItem, null) : editedItem)
        })

        // check deleted items
        originalCollection.forEach((item: any) => {
            const itemId = item.id
            let editedItem = null

            for (let i = 0; i < editedCollection.length; i++) {
                editedItem = editedCollection[i]

                if (itemId === editedCollection[i].id)
                    return
            }

            const itemToDelete = {
                id: item.id,
                DELETE: true
            }

            changes.push(afterEachEvalFn ? afterEachEvalFn(itemToDelete, editedItem, item) : itemToDelete)
        })

        return changes
    }

    private getFormattedDate(dateStr: string): string {
        const date = new Date(dateStr)

        let hours: number = date.getHours()
        let minutes: number | string = date.getMinutes()
        let ampm: string = hours >= 12 ? 'pm' : 'am'
        hours = hours % 12
        hours = hours ? hours : 12
        minutes = minutes < 10 ? '0' + minutes : minutes
        const time = hours + ':' + minutes + ampm

        return monthNames[date.getMonth()] + ' ' + date.getDate() + ' ' + date.getFullYear() + ' ' + time;
    }

    private displayMessage(
        savedSuccessfullyMessage: string,
        messageType: MessageType = 'message',
        displayTime: number = 0
    ) {
        this.setState({
            messageType,
            savedSuccessfullyMessage
        });

        if (displayTime > 0)
            setTimeout(() => {
                this.setState({
                    messageType: 'message',
                    savedSuccessfullyMessage: null
                })
            }, displayTime);
    }

    private handleSaveEditedRequestError(errorJSON: any) {
        let errorMessage: string =  'Error updating document. Document not updated.';

        if (errorJSON && errorJSON.dataSaveStatus) {
            const changes = this.getFilteredChangesFromAgreement();
            const saveStatus = errorJSON.dataSaveStatus;

            if (changes.paragraphs && saveStatus.paragraphs) {
                return window.location.reload();
            }
            else if (changes.relatedAgreementIds && saveStatus.relatedAgreementIds) {
                errorMessage = 'Document saved with 1 error: Paragraphs not saved';
                
            }
            else if (changes.signees && saveStatus.signees) {
                errorMessage = 'Document saved with 2 errors: Related Agreements and Paragraphs not saved';
            }
            else if (changes.metadataIds && saveStatus.metadataIds) {
                errorMessage = 'Document saved with 3 errors: Signees, Related Agreements and Paragraphs not saved';
            }
            else if (changes.self && saveStatus.self) {
                errorMessage = 'Document saved with 4 errors: Metadata, Signees, Related Agreements and Paragraphs not saved';
            }
        }

        this.displayMessage(errorMessage, 'error');
    }

    private saveNew() {
        if (this.saving) return;
        
        this.saving = true;
        this.setState({
            saving: true
        });

        const newAgreementData = this.getFilteredChangesFromAgreement();
        const pdfFilesToUpload: any[] = newAgreementData.pdfFiles || [];
        delete newAgreementData.pdfFiles;

        const successCreateFn = (agreementId: number) => {
            getAgreementList();
            getMetadata();

            this.editions = {
                agreement: {}
            }
            this.displayMessage(
                'Agreement created successfully',
                'success',
                8000
            );
            this.redirectId = agreementId;
            this.fetchAgreementFullData(agreementId);
            this.setState({
                saving: false
            });
            this.saving = false;
        };

        const failureCreateFn = () => {
            this.displayMessage(
                'Error creating document. Document not created.',
                'error'
            );
            this.setState({
                saving: false
            });
            this.saving = false;
        };

        if (Object.keys(newAgreementData).length) {
            this.displayMessage('Creating agreement...');

            createAgreement(newAgreementData).then(
                (createdAgreementId) => {
                    if (pdfFilesToUpload.length) {
                        this.displayMessage('Uploading pdf files...');

                        const errorMessages: string[] = [];
                        let pdfUploadedDone = 0;

                        const checkPdfRequestsDone = () => {
                            if (pdfUploadedDone === pdfFilesToUpload.length) {
                                if (errorMessages.length) {
                                    this.displayMessage(
                                        'Document created with errors: ' + errorMessages.join(' / ') + '.',
                                        'danger'
                                    );
                                }
                                else {
                                    successCreateFn(createdAgreementId);
                                }
                            }
                        };

                        for (let i = 0; i < pdfFilesToUpload.length; i++) {
                            uploadPdfFile(
                                this.props.agreement.id,
                                pdfFilesToUpload[i].file,
                                pdfFilesToUpload[i].metadataId
                            ).then(
                                () => {
                                    pdfUploadedDone++;
                                    checkPdfRequestsDone();
                                },
                                errorMessage => {
                                    pdfUploadedDone++;
                                    errorMessages.push(errorMessage);
                                    checkPdfRequestsDone();
                                }
                            );
                        }
                    }
                    else {
                        successCreateFn(createdAgreementId);
                    }
                },
                failureCreateFn
            )
        }
    }

    private saveEdited() {
        if (this.saving) return;

        this.saving = true;
        this.setState({
            saving: true
        });

        const agreementChanges = this.getFilteredChangesFromAgreement();
        const pdfFilesToUpload: any[] = [];

        if (agreementChanges.pdfFiles) {
            agreementChanges.pdfFiles = agreementChanges.pdfFiles.filter((pdfFile: any) => {
                if (!pdfFile.id) {
                    pdfFilesToUpload.push(pdfFile);
                    return false;
                }

                return true;
            });

            if (!agreementChanges.pdfFiles.length) {
                delete agreementChanges.pdfFiles;
            }
        }

        const successSaveFn = (agreementId: any, pdfUrl: any = '') => {
            this.editions = {
                agreement: {}
            };
            this.displayMessage(
                'Agreement updated successfully',
                'success',
                8000
            );

            getAgreement(agreementId)
                .then(
                    agreement => {
                        this.setState({
                            agreement: {
                                ...agreement as any,
                                pdfUrl

                            },
                            isNew: false,
                            duplicatedIds: this.getDuplicatedParagraphIds(agreement),
                            saving: false
                        });

                        this.saving = false;
                    },
                    error => {}
                );

            window.location.reload();
        };

        const failureSaveFn = (error: any) => {
            if (error.json) {
                error.json().then((errorJSON: any) => {
                    this.saving = false;
                    this.setState({
                        saving: false
                    });
    
                    this.handleSaveEditedRequestError(errorJSON);
                });
            }
            else {
                this.saving = false;
                this.setState({
                    saving: false
                });

                this.handleSaveEditedRequestError(error);
            }
        };

        this.displayMessage('Updating document...');

        updateAgreement(this.props.agreement.id, agreementChanges)
            .then(
                () => {
                    if (pdfFilesToUpload.length) {
                        this.displayMessage('Uploading pdf files...');

                        const errorMessages: string[] = [];
                        let pdfUploadedDone = 0;

                        const checkPdfRequestsDone = () => {
                            if (pdfUploadedDone === pdfFilesToUpload.length) {
                                if (errorMessages.length) {
                                    this.displayMessage(
                                        'Document saved with errors: ' + errorMessages.join(' / ') + '.',
                                        'danger'
                                    );
                                }
                                else {
                                    successSaveFn(this.props.agreement.id);
                                }
                            }
                        };

                        for (let i = 0; i < pdfFilesToUpload.length; i++) {
                            uploadPdfFile(
                                this.props.agreement.id,
                                pdfFilesToUpload[i].file,
                                pdfFilesToUpload[i].metadataId
                            ).then(
                                () => {
                                    pdfUploadedDone++;
                                    checkPdfRequestsDone();
                                },
                                errorMessage => {
                                    pdfUploadedDone++;
                                    errorMessages.push(errorMessage);
                                    checkPdfRequestsDone();
                                }
                            );
                        }
                    }
                    else {
                        successSaveFn(this.props.agreement.id);
                    }

                    getAgreementList();
                    getMetadata();
                },
                failureSaveFn
            )
    }

    private hasChanges() {
        return Object.keys(this.editions.agreement).length > 0;
    }

    private isDirectDownloadLink(filePath?: string): boolean {
        return Boolean(
            !!filePath
            && filePath.trim().length
            && (String(filePath.split('.').pop()).toLowerCase() === 'pdf')
        )
    }

    private handleChangeParagraphList(paragraphs: any[]) {
        this.edit('agreement.paragraphs', paragraphs)
    }

    private handleChangeConflictType(conflictTypeId: number) {
        this.edit('agreement.metadataIds', this.state.agreement.metadataIds.indexOf(conflictTypeId) === -1 ? (
            [...this.state.agreement.metadataIds, conflictTypeId]
        ) : (
            this.state.agreement.metadataIds.filter((id: number) => id !== conflictTypeId)
        ))
    }

    private handleChangeRegion(selectedId: number) {
        this.editUniqueMetadata(
            selectedId,
            this.props.regionList || []
        )
    }

    private handleChangeLanguage(selectedId: number) {
        this.editUniqueMetadata(
            selectedId,
            this.props.languageList || []
        )
    }

    private handleChangeTranslation(selectedId: number) {
        this.editUniqueMetadata(
            selectedId,
            this.props.translationList || []
        )
    }

    private handleChangeTypeOfInstrument(selectedId: number) {
        this.editUniqueMetadata(
            selectedId,
            this.props.typeOfInstrumentList || []
        )
    }

    private handleChangeLocationOfConflict(selectedIds: number[]) {
        this.edit('agreement.metadataIds', [
            ...this.state.agreement.metadataIds.filter((metadataId: number) => (this.props.locationOfConflictList || []).map(loc => loc.id).indexOf(metadataId) === -1),
            ...selectedIds
        ])
    }

    private handleChangeStatus(options: any) {
        const newMetadataIds: any = new Set(this.state.agreement.metadataIds)

        Array.prototype.forEach.call(options, (option, i) => {
            const value = Number(option.value)
            const selected = option.selected
            const hasValue = newMetadataIds.has(value)

            if (selected && !hasValue)
                newMetadataIds.add(value)

            else if (!selected && hasValue)
                newMetadataIds.delete(value)
        })

        this.edit('agreement.metadataIds', [...newMetadataIds])
    }

    private handleChangeRelatedAgreement(value: any, index: number) {
        const { relatedAgreementIds } = this.state.agreement
        this.edit('agreement.relatedAgreementIds', relatedAgreementIds.map((relatedAgreementId: number, i: number) => index === i ? value : relatedAgreementId))
    }

    private handleAddRelatedAgreement() {
        this.edit('agreement.relatedAgreementIds', [...this.state.agreement.relatedAgreementIds, undefined])
    }

    private handleRemoveRelatedAgreement(index: number) {
        this.edit('agreement.relatedAgreementIds', this.state.agreement.relatedAgreementIds.filter((relatedAgreementId: number, i: number) => i !== index))
    }

    private handleChangeLanguageOfPdf(idValue: string, pdfFileIndex: number) {
        const editedPdfFiles = [...this.state.agreement.pdfFiles];
        editedPdfFiles[pdfFileIndex].metadataId = Number(idValue);
        this.edit('agreement.pdfFiles', editedPdfFiles);
    }

    private handleClickRemovePdfFile(pdfFileIndex: number) {
        this.edit('agreement.pdfFiles', this.state.agreement.pdfFiles.filter((pdfFileData: any, index: number) => index !== pdfFileIndex));
    }

    private handleClickAddPdfFile(inputElement: HTMLInputElement) {
        if (inputElement.files && inputElement.files[0]) {
            this.edit('agreement.pdfFiles', [...this.state.agreement.pdfFiles, {
                file: inputElement.files[0]
            }]);

            inputElement.value = '';
        }
    }

    private handleSave(e: React.MouseEvent) {
        e.preventDefault();

        if (this.saving) return;

        if (this.state.isNew)
            this.saveNew();

        else
            this.saveEdited();
    }

    private deleteDuplicatedParagraphs() {
        deleteParagraphs(this.state.duplicatedIds)
            .then(() => window.location.reload());
    }

    private formattedDate(rawDate: string): string {
        const date = new Date(rawDate);
        return `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()}`;
    }

    render() {
        const {
            languageList,
            conflictTypeList,
            regionList,
            statusList,
            agreementList,
            translationList,
            locationOfConflictList,
            typeOfInstrumentList
        } = this.props;

        const {
            agreement,
            messageType,
            savedSuccessfullyMessage,
            saving,
            duplicatedIds,
            // pdfFiles
        } = this.state;

        const pristineAgreement = this.props.agreement;
        const redirectId = this.redirectId;
        const whiteSectionDesign: SectionDesign = 'white';

        if (this.redirectId)
            this.redirectId = null

        let languageValue
        let translationValue
        let locationOfConflictValue
        let typeOfInstrumentValue

        if (agreement) {
            languageValue = this.thisContainsOneOfThat(agreement.metadataIds, (languageList || []).map(lang => lang.id))
            translationValue = this.thisContainsOneOfThat(agreement.metadataIds, (translationList || []).map(m => m.id))
            locationOfConflictValue = this.thisContainsManyOfThat(agreement.metadataIds, (locationOfConflictList || []).map(loc => loc.id))
            typeOfInstrumentValue = this.thisContainsOneOfThat(agreement.metadataIds, (typeOfInstrumentList || []).map(m => m.id))

            if (agreement.lastEditor && !agreement.lastEditor.name) {
                agreement.lastEditor.name = agreement.lastEditor.email;
            }
        }

        const saveAndCSVButton = (
            agreement && (
                this.state.isNew || this.hasChanges()
                    ? (
                        <button
                        type="button"
                        className="lop-btn lop-btn-dark btn-save-editions"
                        onClick={ (e: React.MouseEvent) => this.handleSave(e) }
                        disabled={ saving || !this.hasChanges() }>
                            { saving ? 'Saving...' : 'Save' }
                        </button>
                    )
                    : (
                        <a
                        target="_blank"
                        className="lop-btn btn-save-editions"
                        href={ getEndpoint(`agreements/${agreement.id}/csv`) }>
                            Export to CSV
                        </a>
                    )
            )
        );

        return (
            <>
                { redirectId ? <Navigate to={ '/agreement/' + redirectId }/> : null }
                <TopBar/>
                <MessageBar message={ savedSuccessfullyMessage } type={ messageType }/>
                <Content>
                    <Section>
                        <DebugInfo name='Agreement' data={ {
                            agreement: {...agreement, paragraphs: undefined, signees: undefined},
                            pristineAgreement: {...pristineAgreement, paragraphs: undefined, signees: undefined}
                        } } collapsed/>
                    </Section>
                    <Section>
                        <div className="row">
                            <div className="col-6">
                                <Link to="/" className="lop-btn lop-btn-outline lop-small">
                                    Go Back
                                </Link>
                            </div>
                            <div className="col-6">
                                { saveAndCSVButton }
                            </div>
                        </div>
                    </Section>
                    { agreement ? (
                        <>
                            <Section>
                                <h2>
                                    <span className="lop-prefix">{ this.state.isNew ? 'New Agreement' : 'Edit:' }</span>
                                    { !this.state.isNew && pristineAgreement.title }
                                </h2>
                                <h4>General information</h4>
                            </Section>
                            <Section design={ whiteSectionDesign }>
                                <div className="row">
                                    <div className="col-lg-10 lop-form-group">
                                        <div className="lop-label">
                                            <div className="lop-section-title">Title</div>
                                            <input
                                                type="text"
                                                className="lop-input"
                                                maxLength={ 500 }
                                                value={ agreement.title || '' }
                                                onChange={ e => this.edit('agreement.title', e.target.value) }/>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 lop-form-group">
                                        <div className="lop-label">
                                            <div className="lop-section-title">Conflict Type</div>
                                        </div>
                                        <div className="row">
                                            { (conflictTypeList || []).map((conflictType, index) => (
                                                <div className="col-6" key={ index }>
                                                    <Checkbox
                                                        checked={ agreement.metadataIds.indexOf(conflictType.id) > -1 }
                                                        onChange={ (e: Event) => this.handleChangeConflictType(conflictType.id) }
                                                        value={ conflictType.id }
                                                        children={ conflictType.name }/>
                                                </div>
                                            )) }
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 offset-lg-1 lop-form-group">
                                        <div className="lop-label">
                                            <div className="lop-section-title">Region</div>
                                        </div>
                                        <div className="row">
                                            { (regionList || []).map((region, index) => (
                                                <div className="col-6" key={ index }>
                                                    <Radio
                                                        value={ region.id }
                                                        checked={ agreement.metadataIds.indexOf(region.id) !== -1 }
                                                        onChange={ (e: Event) => this.handleChangeRegion(region.id) }
                                                        children={ region.name }/>
                                                </div>
                                            )) }
                                        </div>
                                        
                                    </div>
                                    <div className="col-6 col-lg-4 lop-form-group">
                                        <div className="lop-label">
                                            <div className="lop-section-title">Language</div>
                                            <select className="lop-input"
                                                value={ languageValue }
                                                onChange={ (e: any) => this.handleChangeLanguage(e.target.value) }>
                                                    { !languageValue ? <option>...</option> : '' }
                                                    { (languageList || []).map((lang, index) => (
                                                        <option key={ index } value={ lang.id }>{ lang.name }</option>
                                                    )) }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 offset-lg-1 lop-form-group">
                                        <div className="lop-label">
                                            <div className="lop-section-title">Date</div>
                                            <input className="lop-input" type="date"
                                                value={ (agreement.date || '').split('T')[0] }
                                                onChange={ e => this.edit('agreement.date', e.target.value) }/>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <SigneeList
                                    data={ agreement.signees }
                                    onChange={ (newSigneeList: any) => this.edit('agreement.signees', newSigneeList) }/>
                                <hr/>
                                <div className="row">
                                    <div className="col-12 col-lg-4">
                                        <label className="lop-label">
                                            <div className="lop-section-title">Status</div>
                                            <select className="lop-input" multiple value={ agreement.metadataIds } onChange={ e => this.handleChangeStatus(e.target.options) }>
                                                { (statusList || []).map((status, index) => (
                                                    <option key={ index } value={ status.id }>
                                                        { status.name }
                                                    </option>
                                                )) }
                                            </select>
                                        </label>
                                    </div>
                                </div>
                                <hr/>
                                <div className="row">
                                    <div className="col-12 col-lg-10">
                                        <div className="lop-label">
                                            <div className="lop-section-title">Related Agreements</div>
                                        </div>
                                    </div>
                                </div>
                                { agreement.relatedAgreementIds.map((id: number, index: number) => 
                                    <div className="row" key={ index } style={ {marginBottom: '7px'} }>
                                        <div className="col-10">
                                            <Multitext
                                                value={ id }
                                                options={ (agreementList || []).map(agreement => ({
                                                    label: `${agreement.title || ''} (${agreement.date ? this.formattedDate(agreement.date) : ''})`,
                                                    value: agreement.id
                                                })) }
                                                onChange={ (newValue: any) => this.handleChangeRelatedAgreement(newValue, index) }
                                                filter={ true }
                                            />
                                        </div>
                                        <div className="col-2">
                                            <button className="lop-btn lop-btn-outline lop-small" onClick={ e => this.handleRemoveRelatedAgreement(index) }>
                                                Remove
                                            </button>
                                        </div>
                                    </div>
                                ) }
                                <div className="row lop-form-group" style={ {marginTop: '15px'} }>
                                    <div className="col-12 col-lg-10">
                                        <ButtonPlus onClick={ (e: React.MouseEvent) => this.handleAddRelatedAgreement() } disabled={ agreement.relatedAgreementIds.length && (!agreement.relatedAgreementIds[agreement.relatedAgreementIds.length - 1]) }>
                                            Add{ agreement.relatedAgreementIds.length ? ' another ' : ' '}Related Agreement
                                        </ButtonPlus>
                                    </div>
                                </div>
                                <hr/>
                                <div className="row lop-form-group">
                                    <div className="col-12 col-lg-10">
                                        <label className="lop-label">
                                            <div className="lop-section-title">Original / Translation</div>
                                            <select
                                                className="lop-input"
                                                value={ translationValue }
                                                onChange={ (e: any) => this.handleChangeTranslation(e.target.value) }>
                                                    { !translationValue ? <option>...</option> : '' }
                                                    { (translationList || []).map((t, index) => (
                                                        <option key={ index } value={ t.id }>{ t.name }</option>
                                                    )) }
                                            </select>
                                        </label>
                                    </div>
                                </div>
                                <div className="row lop-form-group">
                                    <div className="col-12 col-lg-10">
                                        <label className="lop-label">
                                            <div className="lop-section-title">Location of Conflict</div>
                                        </label>
                                    </div>
                                    <div className="col-12 col-lg-10">
                                        <Multitext
                                            values={ locationOfConflictValue }
                                            options={ (locationOfConflictList || []).map(opt => ({label: opt.name, value: opt.id})) }
                                            onChange={ (newValue: any) => this.handleChangeLocationOfConflict(newValue) }
                                            filter={ true }
                                        />
                                    </div>
                                </div>
                                <div className="row lop-form-group">
                                    <div className="col-12 col-lg-10">
                                        <label className="lop-label">
                                            <div className="lop-section-title">Type of Instrument</div>
                                            <select
                                                className="lop-input"
                                                value={ typeOfInstrumentValue }
                                                onChange={ (e: any) => this.handleChangeTypeOfInstrument(e.target.value) }>
                                                    { !typeOfInstrumentValue ? <option>...</option> : '' }
                                                    { (typeOfInstrumentList || []).map((t, index) => (
                                                        <option key={ index } value={ t.id }>{ t.name }</option>
                                                    )) }
                                            </select>
                                        </label>
                                    </div>
                                </div>
                            </Section>
                            { (duplicatedIds.length > 0) && (
                                <Section>
                                    <div className="lop-duplicated-paragraphs-info">
                                        <p>It seems there are {duplicatedIds.length} duplicate paragraph{duplicatedIds.length === 1 ? '' : 's'}.&nbsp;
                                            <strong onClick={ e => this.deleteDuplicatedParagraphs()}>
                                                Click here to delete them automatically.
                                            </strong>
                                        </p>
                                    </div>
                                </Section>
                            ) }
                            <Section>
                                <ParagraphList
                                    data={ agreement.paragraphs }
                                    duplicatedIds={ duplicatedIds }
                                    onChange={ (newList: any) => this.handleChangeParagraphList(newList) }
                                    perPage={ 100 }/>
                            </Section>
                            <Section design={ whiteSectionDesign }>
                                <div className="row">
                                    <div className="col-10 lop-form-group">
                                        <label className="lop-label">
                                            <div className="lop-section-title">
                                                Notes
                                                <CaracterCounter limit={ 1024 } text={ agreement.notes || '' }/>
                                            </div>
                                            <textarea
                                                className="lop-text-area"
                                                value={ agreement.notes || '' }
                                                onChange={ e => this.edit('agreement.notes', e.target.value)}
                                                maxLength={ 1024 }/>
                                        </label>
                                    </div>
                                    <div className='col-10 lop-form-group'>
                                        <label className="lop-label">
                                            <div className="lop-section-title">
                                                PDF files
                                            </div>
                                        </label>
                                        { agreement.pdfUrl && (
                                            <div className="row">
                                                <div className="col">
                                                    <label className="lop-label">
                                                        <input type="text" className="lop-input" value={ agreement.pdfUrl } disabled/>
                                                    </label>
                                                </div>
                                                {
                                                    this.isDirectDownloadLink(agreement.pdfUrl)
                                                    ? (
                                                        <div className="col-auto">
                                                            <label>
                                                                <a type="button" className="lop-btn lop-small" target="_blank" download href={ agreement.pdfUrl + '?download' }>
                                                                    Download
                                                                </a>
                                                            </label>
                                                        </div>
                                                    )
                                                    : (
                                                        null
                                                    )
                                                }
                                                { !agreement.pdfUrl || (
                                                    <div className="col-auto">
                                                        <label>
                                                            <button type="button" className="lop-btn lop-btn-outline lop-small" onClick={ () => this.edit('agreement.pdfUrl', '') }>
                                                                Remove
                                                            </button>
                                                        </label>
                                                    </div>
                                                ) }
                                            </div>
                                        ) }

                                        { agreement.pdfFiles && agreement.pdfFiles.map((pdfFileData: any, index: number) => (
                                            <div className="row" style={ {marginTop: '7px'} } key={ index }>
                                                <div className="col">
                                                    <label className="lop-label">
                                                        <input type="text" className="lop-input" value={ pdfFileData.link || pdfFileData.file.name } disabled/>
                                                    </label>
                                                </div>
                                                <div className='col-auto'>
                                                    <select className="lop-input"
                                                        value={ pdfFileData.metadataId || 0 }
                                                        onChange={ (e: any) => this.handleChangeLanguageOfPdf(e.target.value, index) }>
                                                            { !pdfFileData.metadataId ? <option>...</option> : '' }
                                                            { (languageList || []).map((lang, index) => (
                                                                <option key={ index } value={ lang.id }>{ lang.name }</option>
                                                            )) }
                                                    </select>
                                                </div>
                                                { pdfFileData.id && (
                                                    <div className="col-auto">
                                                        <a type="button" className="lop-btn lop-small" target="_blank" download href={ pdfFileData.linkDownload }>
                                                            Download
                                                        </a>
                                                    </div>
                                                ) }
                                                <div className="col-auto">
                                                    <button type='button' className="lop-btn lop-btn-outline lop-small" onClick={ () => this.handleClickRemovePdfFile(index) }>
                                                        Remove
                                                    </button>
                                                </div>
                                            </div>
                                        )) }
                                    </div>
                                    <div className='col-10'>
                                        <label>
                                            <ButtonPlus>
                                                Add PDF file
                                            </ButtonPlus>
                                            <input hidden type="file" accept="application/pdf" onChange={ e => this.handleClickAddPdfFile(e.target) }/>
                                        </label>
                                    </div>
                                </div>
                            </Section>
                            <Section>
                                <div className="row">
                                    <div className="col-6">
                                        { (agreement.updatedAt || agreement.lastEditor) ? (
                                            <p>
                                                <span>Last edition: </span>
                                                <strong>
                                                    { agreement.lastEditor ? (
                                                        <a href={ 'mailto:' + agreement.lastEditor.email }>
                                                            { agreement.lastEditor.name }
                                                        </a>
                                                    ) : '' }
                                                    { agreement.updatedAt ? (
                                                        (agreement.lastEditor ? ', ' : '') + this.getFormattedDate(agreement.updatedAt)
                                                    ) : ''}
                                                </strong>
                                            </p>
                                        ) : '' }
                                    </div>
                                    <div className="col-6">
                                        { saveAndCSVButton }
                                    </div>
                                </div>
                            </Section>
                        </>
                    ) : (
                        <Section>
                            <Spinner message='Loading agreement'/>
                        </Section>
                    ) }
                </Content>
            </>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Edit)