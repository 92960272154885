import React from 'react';
import './checkbox.styles.scss';
import checkboxIcon from '../../../assets/icons/checkbox@3x.png';

interface CheckboxProps {
    onChange?(event: any): void,
    checked?: boolean,
    disabled?: boolean,
    value?: string,
    children?: React.ReactNode
}

const Checkbox: React.FunctionComponent<CheckboxProps> = props => {
    return (
        <div className="lop-checkbox">
            <label>
                <input type="checkbox" className="lop-checkbox-core"
                disabled={ props.disabled }
                onChange={ props.onChange }
                checked={ props.checked }
                value={ props.value }/>

                <div className="lop-checkbox-box">
                    <img className="lop-checkbox-mark" src={ checkboxIcon }/>
                </div>
                <div className="lop-checkbox-content">
                    { props.children }
                </div>
            </label>
        </div>
    )
};

export default Checkbox;
