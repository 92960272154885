import React from 'react';
import Multitext, { MultitextOption } from '../multitext/multitext.component';
import './tags.styles.scss';
import { connect } from 'react-redux';

interface TagsProps {
    onChange?(newTags: any[]): void,
    data: any,
    editable?: boolean,
    tagList: any[]
}

interface TagsState {
    filterCriteria: string
}

const initialState: TagsState = {
    filterCriteria: ''
};

const mapStateToProps = (state: any) => {
    return {
        tagList: state.tagList.data
    };
};

let copiedTagIds: number[] | null = null;

class Tags extends React.Component<TagsProps, TagsState> {
    public state: TagsState = { ...initialState };

    handleType(str: string) {
        this.setState({
            filterCriteria: str
        });
    }

    handleChange(newTags: any[]) {
        if (this.props.onChange)
            this.props.onChange(newTags);
    }

    handleCopy() {
        copiedTagIds = this.props.data || null;
    }

    handlePaste() {
        if (copiedTagIds) {
            const newData = [...this.props.data, ...copiedTagIds];
            
            this.handleChange(
                newData.filter((tagId, index) => newData.indexOf(tagId) === index)
            );
        }
    }

    getTagsFromIdList(idList: number[]) {
        const { tagList } = this.props;

        const getTagFromId = (tagId: number, tagList: any[]): any => {
            for (const tag of tagList) {
                if (tag.id === tagId)
                    return tag

                else if (tag.childrenTags && tag.childrenTags.length) {
                    const childTag = getTagFromId(tagId, tag.childrenTags)

                    if (childTag)
                        return childTag
                }
            }
        };

        return idList.map((tagId: number) => getTagFromId(tagId, tagList)).filter((tag: any) => tag);
    }

    private getTagOptionsOld(tagList: any[]): any {
        return tagList.map(tagData => ({
            label: tagData.name,
            value: tagData.id,
            children: tagData.childrenTags.length ? this.getTagOptionsOld(tagData.childrenTags) : null
        }));
    }

    private getTagOptions(tagList: any[]): MultitextOption[] {
        const result: MultitextOption[] = [];
        const addTagsToResult = (tagItem: any, level: number = 0) => {
            result.push({
                value: tagItem.id,
                label: tagItem.name,
                level,
                disabled: tagItem.childrenTags && (tagItem.childrenTags.length > 0)
            });
            if (tagItem.childrenTags) {
                tagItem.childrenTags.forEach((tagData: any) => {
                    addTagsToResult(tagData, level + 1);
                });
            }
        }
        tagList.forEach((tagItem: any) => addTagsToResult(tagItem, 0));
        return result;
    }

    render() {
        const { data, tagList, editable } = this.props;

        return (
            <div className="c-tags-list">
                { editable ? (
                    <div className="c-tags">
                        <div className="c-tags-title">
                            Article Tags
                        </div>
                        <Multitext
                            values={ data }
                            options={ this.getTagOptions(tagList) }
                            filter={ true }
                            onChange={ (newTags: any[]) => this.handleChange(newTags) }
                        />
                        <div className="c-tags-buttonpad">
                            <button
                                type="button"
                                className="c-tags-btn"
                                onClick={ () => this.handleCopy() }>
                                    Copy tags
                            </button>
                            <button
                                type="button"
                                className="c-tags-btn"
                                onClick={ () => this.handlePaste() }
                                disabled={ !copiedTagIds }>
                                    Paste tags
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="c-tags-readonly">
                        <span className="c-tags-title">
                            Article Tags:
                        </span>
                        <ul className="c-tag-list">
                            { this.getTagsFromIdList(data).map(tag =>
                                <li className="c-tag-list-item" key={ tag.id }>{ tag.name }</li>
                            ) }
                        </ul>
                    </div>
                ) }
            </div>
        );
    }
}

export default connect(mapStateToProps, {})(Tags);