import React from 'react';
import './topbar.styles.scss';
import Menu from '../menu/menu.component';
import peacemakerLogo from '../../assets/img/peacemaker-logo@3x.png';
import cambridgeLogo from '../../assets/img/cambridge-logo@3x.png';
import { connect } from 'react-redux';
import logo from '../../assets/img/lop-logo@3x.png';
import { Link } from 'react-router-dom';

interface TopbarProps {
    username?: string,
    userIsAdmin: boolean,
    children?: React.ReactNode
}

const mapStateToProps = (state: any) => ({
    username: state.session.displayName || state.session.email,
    userIsAdmin: state.session.userIsAdmin
})

const Topbar: React.FunctionComponent<TopbarProps> = props => {
    return (
        <div className="lop-top-bar-area">
            <header className="lop-top-bar">
                <div className="lop-top-bar-left">
                    <div className="lop-top-bar-menu-area">
                        <Menu username={ props.username } superAdmin={ props.userIsAdmin }/>
                    </div>
                    <Link to="/">
                        <img className="lop-top-bar-logo" src={ logo } alt="Language Of Peace" height="33"/>
                    </Link>
                </div>
                <div className="lop-top-bar-center">
                    { props.children }
                </div>
                <div className="lop-top-bar-right">
                    <div className="lop-logo-list">
                        <img src={ peacemakerLogo } alt="United Nations Peacemaker" height="37"/>
                        <img src={ cambridgeLogo } alt="University Of Cambridge" height="33"/>
                    </div>
                </div>
            </header>
        </div>
    )
};

export default connect(mapStateToProps)(Topbar)