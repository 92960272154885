import React, { useEffect, useState } from 'react';

interface DebugInfoListProps {
    name?: string;
    list?: {[itemName: string]: string | number | object};
    data?: object;
    collapsed?: boolean;
}

const DebugInfo: React.FC<DebugInfoListProps> = ({
    name,
    list,
    data,
    collapsed = false
}) => {
    const [collapsedInfo, changeCollapsedInfo] = useState<boolean>(collapsed);
    const preElement = (content: object) => (
        <pre style={ {
            background: '#2e2e2e',
            borderRadius: '10px',
            padding: '10px 15px',
            fontSize: '11px',
            color: 'navajowhite',
            margin: 0
        } }>
             { JSON.stringify(content, undefined, 4) }
        </pre>
    );

    return (
        process.env.REACT_APP_DEBUG_MODE === 'true'
        ? (
            <div
            style={ {
                display: 'block',
                background: 'gainsboro',
                borderRadius: '10px',
                padding: '0 15px',
                overflow: 'auto',
                margin: '10px 0',
                width: '100%'
            } }>
                <div style={ {
                    fontWeight: 'bolder',
                    margin: '0 -15px',
                    cursor: 'pointer',
                    padding: '10px 15px'
                } }
                onMouseEnter={ e => {
                    const target = e.target as HTMLDivElement;
                    target.style.background = '#c3c3c3';
                } }
                onMouseLeave={ e => {
                    const target = e.target as HTMLDivElement;
                    target.style.background = 'transparent';
                } }
                onClick={ e => {
                    e.preventDefault();
                    e.stopPropagation();
                    changeCollapsedInfo(!collapsedInfo);
                } }>
                    DebugInfo
                    { name && `: ${name}` }
                </div>
                { !collapsedInfo && (
                    <div>
                        { list && (
                            <ul style={ {
                                padding: '0 0 0 15px',
                                margin: '10px 0',
                                display: 'block'
                            } }>
                                { Object.keys(list).map((itemName, index) => (
                                    <li key={ index }>
                                        { itemName }: { (() => {
                                            const itemValue = list[itemName];

                                            if (typeof itemValue === 'object') {
                                                return preElement(itemValue);
                                            }
                                            else {
                                                return itemValue;
                                            }
                                        })() }
                                    </li>
                                )) }
                            </ul>
                        ) }

                        { data && (
                            <div style={ {
                                margin: '10px 0'
                            } }>
                                { preElement(data) }
                            </div>
                        ) }
                    </div>
                ) }
            </div>
        )
        : <></>
    );
};

export default DebugInfo;
