import React from 'react';

import Signee from '../signee/signee.component';
import ButtonPlus from '../button-plus/button-plus.component';
import Paginator from '../paginator/paginator.component';

interface SigneeListProps {
    onChange?(newData: any): void,
    data: any
}

interface SigneeListState {
    currentPage: number,
    perPage: number
}

const initialState: SigneeListState = {
    currentPage: 1,
    perPage: 4
}

class SigneeList extends React.Component<SigneeListProps, SigneeListState> {
    public state = {
        ...initialState
    }
    private addedIndex = null

    fireOnChange(newData: any) {
        if (this.props.onChange)
            this.props.onChange(newData)
    }

    handleChangeSignee(editedSignee: any, index: number) {
        const { data } = this.props

        if (editedSignee === null) {
            let newCurrentPage = Math.min(this.state.currentPage, Math.ceil((data.length - 1) / this.state.perPage))

            if (newCurrentPage !== this.state.currentPage)
                this.setState({
                    currentPage: newCurrentPage
                })

            this.fireOnChange(
                this.props.data.filter((signee: any, i: number) => i !== index).map((signee: any, index: number) => ({
                    ...signee,
                    order: index
                }))
            );
        }
        else {
            this.fireOnChange(
                this.props.data.map((signee: any, i: number) => i === index ? editedSignee : signee)
            );
        }
    }

    handleClickAdd() {
        this.addedIndex = this.props.data.length;

        if (this.props.data.length === (this.state.perPage * this.state.currentPage))
            this.setState({
                currentPage: this.state.currentPage + 1
            });
        
        this.fireOnChange(
            [...this.props.data, {
                name: '',
                position: '',
                order: this.props.data.length,
                typeCode: 'country',
                metadataId: null
            }]
        );        
    }

    handleChangePage(currentPage: number) {
        this.setState({ currentPage })
    }

    lastSigneeIsNewPristine() {
        const lastSignee = this.props.data[this.props.data.length - 1]
        return (
            (lastSignee && !lastSignee.id) && (
                !Boolean(lastSignee.name) && !Boolean(lastSignee.position) && !lastSignee.metadataId && !Boolean(lastSignee.typeCode)
            )
        )
    }

    render() {
        const { data } = this.props
        const lastPage = Math.ceil(data.length / this.state.perPage)
        const initialIndex = (this.state.perPage * (this.state.currentPage - 1))
        const lastIndex = initialIndex + this.state.perPage - 1
        const addedIndex = this.addedIndex

        if (this.addedIndex !== null)
            this.addedIndex = null

        return (
            <div className="row">
                { data.filter((signee: any, i: number) => ((i >= initialIndex) && (i <= lastIndex))).map((signee: any, i: number) => (
                    <div className="col-12" key={ i + initialIndex }>
                        <Signee
                            title={ 'Signee ' + (i + 1 + initialIndex) }
                            data={ signee }
                            onChange={ (newSignee: any) => this.handleChangeSignee(newSignee, i + initialIndex) }
                            highlight={ (i + initialIndex) === addedIndex }/>
                    </div>
                )) }
                <div className="col-12">
                    <ButtonPlus onClick={ e => this.handleClickAdd() } disabled={ this.lastSigneeIsNewPristine() }>
                        { (!data || !data.length) ? 'Add Signee' : 'Add another Signee' }
                    </ButtonPlus>
                </div>
                { data.length > this.state.perPage
                    ? (
                        <div className="col-12">
                            <Paginator currentPage={ this.state.currentPage } lastPage={ lastPage } onChange={ (newPage: number) => this.handleChangePage(newPage) }/>
                        </div>
                    )
                    : ''
                }
            </div>
        )
    }
}

export default SigneeList;