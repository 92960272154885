import React from 'react';
import { Link } from 'react-router-dom';

import editIcon from '../../assets/icons/edit@3x.png';
import editIconHover from '../../assets/icons/edit-hover@3x.png';
import trashIcon from '../../assets/icons/trash@3x.png';
import trashIconHover from '../../assets/icons/trash-hover@3x.png';

interface AgreementListItemProps {
    agreement: any,
    onChange?(agreement: any): void,
    onDelete?(agreement: any): void
}

function getFormattedDate(dateStr: string): string {
    return (dateStr || '').replace(new RegExp('-', 'g'), '/').split('T')[0]
} 

const AgreementListItem: React.FunctionComponent<AgreementListItemProps> = props => {
    const { agreement, onChange, onDelete } = props;

    const {
        id,
        title,
        locationOfConflictNames,
        date,
        published,
        updatedAt,
    } = agreement;

    function handleChangePublished(value: string) {
        if (onChange)
            onChange({
                ...agreement,
                published: value === 'true'
            })
    }

    function handleClickDelete() {
        if (onDelete)
            onDelete(agreement)
    }

    return (
        <div className="lop-agreement-list-item" data-agreement-id={ id }>
            <div className="lop-agreement-list-item-detail lop-agreement-title">
                <Link to={ '/agreement/' + id } title="Edit" aria-label="Edit">
                    { title }
                </Link>
            </div>
            <div className="lop-agreement-list-item-detail lop-agreement-country">
                { locationOfConflictNames }
            </div>
            <div className="lop-agreement-list-item-detail lop-agreement-date">
                { getFormattedDate(date) }
            </div>
            <div className="lop-agreement-list-item-detail lop-agreement-published">
                <select className="lop-input" value={ published } onChange={ e => handleChangePublished(e.target.value) }>
                    <option value={ 'true' }>Yes</option>
                    <option value={ 'false' }>No</option>
                </select>
            </div>
            <div className="lop-agreement-list-item-detail lop-agreement-updated">
                <span className="lop-date">
                    { getFormattedDate(updatedAt) }
                </span>
            </div>
            <div className="lop-agreement-list-item-detail lop-agreement-actions">
                <Link to={ '/agreement/' + id } className="lop-action" title="Edit" aria-label="Edit">
                    <img alt="Edit" src={ editIcon }/>
                    <img alt="Edit" className="lop-hover-pic" src={ editIconHover }/>
                </Link>
                <a className="lop-action" title="Move to trash" aria-label="Move to trash" onClick={ e => handleClickDelete() }>
                    <img alt="Trash" src={ trashIcon }/>
                    <img alt="Trash" className="lop-hover-pic" src={ trashIconHover }/>
                </a>
            </div>
        </div>
    );
};

export default AgreementListItem;