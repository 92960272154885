import React from 'react';
import './button-plus.styles.scss';
import plusIcon from '../../assets/icons/plus@3x.png';

interface ButtonPlusProps {
    disabled?: boolean,
    onClick?(e: React.MouseEvent): void,
    children?: React.ReactNode
}

const ButtonPlus: React.FunctionComponent<ButtonPlusProps> = props => {
    const { disabled, onClick, children } = props;

    return (
        <a className={ 'lop-button-plus' + (disabled ? ' lop-button-disabled' : '') } onClick={ onClick }>
            <span className="lop-button-icon">
                <img src={ plusIcon }/>
            </span>
            <span className="lop-button-content">
                { children }
            </span>
        </a>
    )
};

export default ButtonPlus;