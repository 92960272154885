import React from 'react';
import {
    SortableContainer,
    SortableElement,
    SortableHandle
} from 'react-sortable-hoc';
import Tags from '../tags/tags.component';
import TextEditor from '../text-editor/text-editor.component';
import Paginator from '../paginator/paginator.component';

import './paragraph-list.styles.scss';
import Icon from '../icon/icon.component';
import dragButton from './drag@3x.png';
import leftButton from './left@3x.png';
import leftButtonHover from './left-hover@3x.png';
import rightButton from './right@3x.png';
import rightButtonHover from './right-hover@3x.png';
import DebugInfo from '../debug-info-list/debug-info-list.component';

function padNumber(str: number | string, max: number): string {
    str = str.toString();
    return str.length < max ? padNumber('0' + str, max) : str;
}

interface ParagraphListProps {
    onChange(newArray: any[]): void,
    data: any,
    perPage?: number,
    duplicatedIds?: number[]
}

interface ParagraphListState {
    selectedParagraphIndex: number,
    animatedSelection: boolean,
    currentPage: number
}

interface EditableListItemProps {
    data: any,
    animated: boolean,
    errorMode: boolean,
    shadowMode: boolean,
    onChange(changes: any): void,
    onSplit(changes: any): void
}

interface ReadonlyListItemProps {
    data: any,
    errorMode: boolean,
    shadowMode: boolean,
    onClick(): void
}

interface SortableListProps {
    children: React.ReactNode
}

const initialState: ParagraphListState = {
    selectedParagraphIndex: -1,
    animatedSelection: true,
    currentPage: 1
};

const maxParagraphLength: number = 20000;
const exceedsTextLength = (text: string): boolean => text.length > maxParagraphLength;

const EditableListItem = SortableElement(({
    data, onChange, onSplit, animated, errorMode, shadowMode
}: EditableListItemProps) => {
    const minLevel = 1;
    const maxLevel = 10;

    const handleEditText = (text: string) => {
        onChange({
            ...data,
            text
        });
    };

    const handleChangeTags = (tagIds: number[]) => {
        onChange({
            ...data,
            tagIds
        });
    };

    const handleChangeLevel = (level: number) => {
        onChange({
            ...data,
            level
        })
    };

    const handleSplitText = (splittedValue: any) => {
        onSplit(splittedValue)
    };

    const handleRemoveButton = () => {
        onChange(null)
    };

    const level = data.level || 1;
    const text = data.text || '';
    const elementClassName = (
        'paragraph-element paragraph-editable'
        + (animated ? ' paragraph-editable-animated' : '')
        + (errorMode ? ' error' : '')
        + (shadowMode ? ' shadow' : '')
    );

    return (
        <div className="paragraph-list-element">
            <div className={ elementClassName }>
                <div>
                    <div className="paragraph-list-controls">
                        <span className="paragraph-list-control" onClick={ e => handleChangeLevel(Math.min(level + 1, maxLevel)) }>
                            <Icon src={ leftButton } hover={ leftButtonHover } x3/>
                        </span>
                        <span className="paragraph-list-control" onClick={ e => handleChangeLevel(Math.max(level - 1, minLevel)) }>
                            <Icon src={ rightButton } hover={ rightButtonHover } x3/>
                        </span>
                        <span className="paragraph-list-control level-info">
                            Level { level }
                        </span>
                        <span className="paragraph-list-control drag-handle">
                            <Handle/>
                        </span>
                    </div>
                    <div className={ 'level-content level-' + padNumber(level, 2) }>
                        <div className="paragraph-content">
                            <TextEditor
                                value={ text }
                                onChange={ (newValue: string) => handleEditText(newValue) }
                                onSplit={ (splittedText: any) => handleSplitText(splittedText) }/>
                        </div>
                    </div>
                    <div className="row paragraph-list-append">
                        <div className="col-12 col-sm-10">
                            <Tags editable={ true } data={ data.tagIds } onChange={ (newTags: number[]) => handleChangeTags(newTags) }/>
                        </div>
                        <div className="col-12 col-sm-2">
                            <div className="paragraph-list-append-right">
                                <div className={ 'paragraph-list-character-counter' + (errorMode ? ' error' : '') }>{ text.length }/{ maxParagraphLength } character{ text.length !== 1 && 's' }</div>
                                <button type="button" className="lop-btn lop-btn-outline lop-small" onClick={ () => handleRemoveButton() }>
                                    Remove
                                </button>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <DebugInfo name='Paragraph being edited' data={ data } collapsed/>
            </div>
        </div>
    )
}) as any;

const ReadonlyListItem = SortableElement(({
    data, onClick, errorMode, shadowMode
}: ReadonlyListItemProps) => {
    const filterToHTML = (text: string) => text.split('\n').map(line => '<div style="margin: 0 0 10px 0;">' + line + '</div>').join('');
    const level = data.level || 1;
    const text = data.text || '';
    const elementClassName = (
        'paragraph-element paragraph-view-only'
        + (errorMode ? ' error' : '')
        + (shadowMode ? ' shadow' : '')
    );

    return (
        <div className="paragraph-list-element" onClick={ shadowMode ? undefined : onClick }>
            <div className={ elementClassName }>
                <div className={ 'level-content level-' + padNumber(level, 2) }>
                    <div className="paragraph-content" dangerouslySetInnerHTML={ {__html: filterToHTML(text)} }/>
                    <div className="paragraph-content-extra">
                        <span className="paragraph-content-extra-level">Level { level }</span>
                        <span className="paragraph-content-extra-tags">
                            <Tags editable={ false } data={ data.tagIds }/>
                        </span>
                    </div>
                </div>
            </div>
            <DebugInfo name='Paragraph above' data={ data } collapsed/>
        </div>
    );
}) as any;

const SortableList = SortableContainer(({ children }: SortableListProps) => {
    return <div className="paragraph-list">{ children }</div>;
}) as any;

const Handle = SortableHandle(() => {
    return <Icon src={ dragButton } x3/>;
})

class ParagraphList extends React.Component<ParagraphListProps, ParagraphListState> {
    public state: ParagraphListState = { ...initialState }
    private sortedData: any;
    private hasErrors: boolean = false;

    selectParagraph(index: number) {
        this.setState({
            selectedParagraphIndex: this.state.selectedParagraphIndex === index ? -1 : index,
            animatedSelection: true
        })
    }

    handleSortEnd(oldIndex: number, newIndex: number) {
        let newArray = [...this.sortedData]

        if (newIndex >= newArray.length) {
            let k = newIndex - newArray.length + 1

            while (k--) {
                newArray.push(undefined)
            }
        }
        newArray.splice(newIndex, 0, newArray.splice(oldIndex, 1)[0]);

        newArray.forEach((paragraph, i) => paragraph.order = i)

        this.props.onChange(newArray);

        this.setState({
            selectedParagraphIndex: newIndex,
            animatedSelection: false
        })
    }

    handleChangeListItem(newListItem: any, index: number) {
        let newList;

        if (newListItem === null) {
            newList = this.sortedData.filter((paragraph: any, i: number) => i !== index).map((paragraph: any, order: number) => ({
                ...paragraph,
                order
            }))

            this.props.onChange(newList);

            this.setState({
                selectedParagraphIndex: -1,
                animatedSelection: true,
                currentPage: Math.min(this.state.currentPage, Math.ceil((this.props.data.length - 1) / (this.props.perPage || 10)))
            })
        }
        else {
            newList = this.sortedData.map((paragraph: any, i: number) => {
                let paragraphData = (i === index) ? newListItem : paragraph
                return paragraphData
            })

            this.props.onChange(newList);
        }
    }

    handleSplitText(splittedText: any, index: number) {
        let newList = [...this.sortedData]

        let prevText = !!splittedText[0] ? {...newList[index], text: splittedText[0]} : null
        let currentText = {...newList[index], text: splittedText[1], tags: '', id: 'CURRENT'}
        let nextText = prevText ? (
            !!splittedText[2] ? {...newList[index], text: splittedText[2], tags: '', id: null} : null
        ) : {...newList[index], text: splittedText[2]}


        newList[index] = currentText
        newList.splice(index, 0, prevText)
        newList.splice(index + 2, 0, nextText)
        newList = newList.filter(el => el)

        let currentIndex = -1
        newList.forEach((el, i) => {
            el.order = i

            if (el.id === 'CURRENT') {
                currentIndex = i
                el.id = null
            }
        })

        if (currentIndex === this.state.selectedParagraphIndex)
            this.setState({
                selectedParagraphIndex: -1
            })

        this.props.onChange(newList);

        this.setState({
            animatedSelection: true,
            selectedParagraphIndex: currentIndex
        })
    }

    handleClickAdd() {
        const { data } = this.props

        this.fireOnChange([...this.sortedData.map((paragraphData: any, order: number) => ({ ...paragraphData, order })), {
            level: 1,
            order: data.length,
            text: '',
            tagIds: []
        }])

        this.setState({
            selectedParagraphIndex: data.length,
            animatedSelection: true,
            currentPage: Math.ceil((data.length + 1) / (this.props.perPage || 10))
        })
    }

    fireOnChange(newList: any[]) {
        if (this.props.onChange)
            this.props.onChange(newList)
    }

    handleChangePage(currentPage: number) {
        this.setState({
            currentPage,
            selectedParagraphIndex: -1
        })
    }

    lastParagraphIsNewAndPristine() {
        const lastParagraph = this.sortedData[this.sortedData.length - 1]

        return (
            (lastParagraph && !lastParagraph.id) && (
                !Boolean(lastParagraph.text) && !lastParagraph.tagIds.length
            )
        )
    }

    render() {
        const {
            data,
            perPage = 10,
            duplicatedIds = []
        } = this.props;
        const { selectedParagraphIndex, animatedSelection } = this.state;
        const lastPage = Math.ceil(data.length / perPage);
        const initialIndex = (perPage * (this.state.currentPage - 1));
        const lastIndex = initialIndex + perPage - 1;

        this.sortedData = [ ...(data || []) ].sort((a, b) => a.order - b.order);

        return (
            <div>
                <SortableList useDragHandle helperClass="dragging-helper" axis="y" lockAxis="y" onSortEnd={ ({oldIndex, newIndex}: any) => this.handleSortEnd(oldIndex, newIndex) }>
                    { this.sortedData.map((listElement: any, i: number) => 
                        (initialIndex <= i) && (i <= lastIndex) ? (
                            selectedParagraphIndex === i ? (
                                <EditableListItem key={ i } index={ i }
                                    data={ listElement }
                                    animated={ animatedSelection }
                                    onChange={ (newListElement: any) => this.handleChangeListItem(newListElement, i) }
                                    onSplit={ (splittedText: any) => this.handleSplitText(splittedText, i) }
                                    errorMode={ exceedsTextLength(listElement.text) }
                                    shadowMode={ duplicatedIds.indexOf(listElement.id) > -1 }/>
                            ) : (
                                <ReadonlyListItem key={ i } index={ i }
                                    data={ listElement }
                                    onClick={ () => this.selectParagraph(i) }
                                    errorMode={ exceedsTextLength(listElement.text) }
                                    shadowMode={ duplicatedIds.indexOf(listElement.id) > -1 }/>
                            )
                        ) : null
                    ) }
                </SortableList>

                { lastPage > 1 ? <Paginator currentPage={ this.state.currentPage } lastPage={ lastPage } onChange={ currentPage => this.handleChangePage(currentPage) }/> : null }

                <button className="lop-btn" onClick={ e => this.handleClickAdd() } disabled={ this.lastParagraphIsNewAndPristine() }>
                    Add{ data.length ? ' next ' : ' ' }article
                </button>
            </div>
        )
    }
}

export default ParagraphList;