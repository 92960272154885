import { handleActions } from 'redux-actions';
import { SelectedAgreementState, SelectedAgreement } from './selected-agreement.types';
import { setSelectedAgreement, removeSelectedAgreement } from './selected-agreement.actions';

const defaultState: SelectedAgreementState = {
    data: null,
    updatedAt: new Date()
}

export default handleActions<SelectedAgreementState, SelectedAgreement>(
    {
        [String(setSelectedAgreement)]: (state, { payload }) => {
            return {
                data: {...payload},
                updatedAt: new Date()
            };
        },
        [String(removeSelectedAgreement)]: (state, { payload }) => {
            return {
                data: null,
                updatedAt: new Date()
            }
        }
    },
    defaultState
);