export const TYPES = {
    SET_AGREEMENT_LIST_DATA: 'SET_AGREEMENT_LIST_DATA',
    SET_AGREEMENT_LIST_LOADING: 'SET_AGREEMENT_LIST_LOADING',
    
    SET_AGREEMENT_VALUE: 'SET_AGREEMENT_VALUE',

    FETCH_AGREEMENT_REQUEST: 'FETCH_AGREEMENT_REQUEST',
    FETCH_AGREEMENT_SUCCESS: 'FETCH_AGREEMENT_SUCCESS',
    FETCH_AGREEMENT_FAILURE: 'FETCH_AGREEMENT_FAILURE',
    EDIT_AGREEMENT: 'EDIT_AGREEMENT',

    FETCH_CONFLICT_TYPES_REQUEST: 'FETCH_CONFLICT_TYPES_REQUEST',
    FETCH_CONFLICT_TYPES_SUCCESS: 'FETCH_CONFLICT_TYPES_SUCCESS',
    FETCH_CONFLICT_TYPES_FAILURE: 'FETCH_CONFLICT_TYPES_FAILURE',

    FETCH_REGION_LIST_REQUEST: 'FETCH_REGION_LIST_REQUEST',
    FETCH_REGION_LIST_SUCCESS: 'FETCH_REGION_LIST_SUCCESS',
    FETCH_REGION_LIST_FAILURE: 'FETCH_REGION_LIST_FAILURE',

    FETCH_LANGUAGE_LIST_REQUEST: 'FETCH_LANGUAGE_LIST_REQUEST',
    FETCH_LANGUAGE_LIST_SUCCESS: 'FETCH_LANGUAGE_LIST_SUCCESS',
    FETCH_LANGUAGE_LIST_FAILURE: 'FETCH_LANGUAGE_LIST_FAILURE',

    FETCH_STATUS_LIST_REQUEST: 'FETCH_STATUS_LIST_REQUEST',
    FETCH_STATUS_LIST_SUCCESS: 'FETCH_STATUS_LIST_SUCCESS',
    FETCH_STATUS_LIST_FAILURE: 'FETCH_STATUS_LIST_FAILURE',

    FETCH_PARAGRAPH_LIST_REQUEST: 'FETCH_PARAGRAPH_LIST_REQUEST',
    FETCH_PARAGRAPH_LIST_SUCCESS: 'FETCH_PARAGRAPH_LIST_SUCCESS',
    FETCH_PARAGRAPH_LIST_FAILURE: 'FETCH_PARAGRAPH_LIST_FAILURE',

    // Real API
    SET_SESSION_VALUES: 'SET_SESSION_VALUES',
    SET_SESSION_USER: 'SET_SESSION_USER',
    SET_SESSION_READY: 'SET_SESSION_READY',
    SET_SESSION_TOKEN: 'SET_SESSION_TOKEN',
    UNSET_SESSION_TOKEN: 'UNSET_SESSION_TOKEN',
    SESSION_HAS_DATA: 'SESSION_HAS_DATA',
    SESSION_HAS_NOT_DATA: 'SESSION_HAS_NOT_DATA',
    SESSION_SET_CLAIMS: 'SESSION_SET_CLAIMS',
    SET_LANGUAGE_LIST: 'SET_LANGUAGE_LIST',
    SET_CONFLICT_TYPE_LIST: 'SET_CONFLICT_TYPE_LIST',
    SET_REGION_LIST: 'SET_REGION_LIST',
    SET_SIGNEE_TYPE_LIST: 'SET_SIGNEE_TYPE_LIST',
    SET_STATUS_LIST: 'SET_STATUS_LIST',
    SET_TAG_LIST: 'SET_TAG_LIST',
    SET_AGREEMENT: 'SET_AGREEMENT',
    SET_PARAGRAPH_LIST: 'SET_PARAGRAPH_LIST',
    SET_SIGNEE_LIST: 'SET_SIGNEE_LIST',
    SET_AGREEMENT_LIST: 'SET_AGREEMENT_LIST',
    SET_COUNTRY_LIST: 'SET_COUNTRY_LIST',
    SET_NON_STATE_ORGANIZATION_LIST: 'SET_NON_STATE_ORGANIZATION_LIST',
    SET_INTERNATIONAL_ORGANIZATION_LIST: 'SET_INTERNATIONAL_ORGANIZATION_LIST',
    CLEAR_AGREEMENT_DATA: 'CLEAR_AGREEMENT_DATA',
    CLEAR_PARAGRAPH_LIST_DATA: 'CLEAR_PARAGRAPH_LIST_DATA',
    SET_TRANSLATION_LIST: 'SET_TRANSLATION_LIST',
    SET_LOCATION_OF_CONFLICT_LIST: 'SET_LOCATION_OF_CONFLICT_LIST',
    SET_TYPE_OF_INSTRUMENT_LIST: 'SET_TYPE_OF_INSTRUMENT_LIST'
}

export const setAgreementList = (list: any) => {
    return {
        type: TYPES.SET_AGREEMENT_LIST,
        payload: list
    }
}

export const clearAgreementData = () => {
    return {
        type: TYPES.CLEAR_AGREEMENT_DATA
    }
}

export const clearParagraphListData = () => {
    return {
        type: TYPES.CLEAR_PARAGRAPH_LIST_DATA
    }
}

export const setSessionToken = (payload: any) => {
    return {
        type: TYPES.SET_SESSION_TOKEN,
        payload
    }
}

export const unsetSessionToken = () => {
    return {
        type: TYPES.UNSET_SESSION_TOKEN,
        payload: null
    }
}
