import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import TopBar from '../../components/topbar/topbar.component';
import AgreementList from  '../../components/agreement-list/agreement-list.component';
import plusIcon from '../../assets/icons/plus@3x.png';
import { clearAgreementData, clearParagraphListData, setAgreementList } from '../../store/actions';
import SearchForm from '../../components/search-form/search-form.component';
import Swal from 'sweetalert2';
import { deleteAgreement, publishAgreement } from '../../api.v3';

interface HomeProps {
    clearAgreementData(): void,
    clearParagraphListData(): void,
    setAgreementList(newAgreementList: any[]): void,
    locationOfConflictList: any[],
    agreementList: any[]
}

interface HomeState {
    loading: boolean,
    titleFilter: string,
    dateFilter: string,
    countryIdFilter: number,
    focusedAgreements: any[]
}

const initialState: HomeState = {
    loading: false,
    titleFilter: '',
    dateFilter: '',
    countryIdFilter: 0,
    focusedAgreements: []
};

const mapStateToProps = (state: any) => {
    return {
        agreementList: state.agreementList.data,
        locationOfConflictList: state.locationOfConflictList.data
    }
};

const mapActionsToProps = {
    clearAgreementData,
    clearParagraphListData,
    setAgreementList
};

class Home extends React.PureComponent<HomeProps, HomeState> {
    public state: HomeState = {
        ...initialState
    }

    handleClickAddAgreement() {
        this.props.clearAgreementData();
        this.props.clearParagraphListData();
    }

    handleChangeAgreementList(newAgreementList: any, changedAgreement: any, deleted: any) {
        const changedAgreementTitle = changedAgreement.title;

        if (deleted) {
            this.setState({
                focusedAgreements: [changedAgreement.id]
            });

            Swal
                .fire({
                    title: 'Are you sure you want to delete the agreement?',
                    text: changedAgreementTitle ? '"' + changedAgreementTitle + '"' : '',
                    icon: 'warning',
                    width: 480,
                    showCancelButton: true,
                    confirmButtonColor: '#005A9C',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes'
                })
                .then((result) => {
                    if (result.value) {
                        deleteAgreement(changedAgreement.id).then(() => {
                            this.props.setAgreementList(newAgreementList)
                        })
                    }
                })
        }
        else {
            publishAgreement(changedAgreement.id, changedAgreement.published)
                .then(() => {
                    this.props.setAgreementList(newAgreementList)
                })
        }
    }

    handleSubmitSearch(e: Event, values: any) {
        e.preventDefault();

        this.setState({
            titleFilter: values.text,
            dateFilter: values.date,
            countryIdFilter: values.countryId
        });
    }

    getLocationsOfConflict(agreement: any): {id: number, name: string}[] {
        const { locationOfConflictList } = this.props;
        const result = [];

        for (const metadataId of agreement.metadataIds) {
            for (const locationOfConflict of locationOfConflictList) {
                if (locationOfConflict.id === metadataId) {
                    result.push({
                        id: locationOfConflict.id,
                        name: locationOfConflict.name
                    })
                }
            }
        }

        return result;
    }

    render() {
        const { loading, titleFilter, dateFilter, countryIdFilter, focusedAgreements } = this.state;
        const { agreementList } = this.props;

        const filteredAgreementList = agreementList
            .map(agreement => {
                const locationsOfConflict = this.getLocationsOfConflict(agreement);
                const locationOfConflict = locationsOfConflict[0];
                const locationOfConflictNameList: string[] = [];
                const locationOfConflictIds: number[] = [];

                locationsOfConflict.forEach(locationOfConflict => {
                    locationOfConflictNameList.push(locationOfConflict.name);
                    locationOfConflictIds.push(locationOfConflict.id);
                });

                locationOfConflictNameList.sort();

                return {
                    ...agreement,
                    locationOfConflictName: locationOfConflict ? locationOfConflict.name : null,
                    locationOfConflictId: locationOfConflict ? locationOfConflict.id : null,
                    locationsOfConflict,
                    locationOfConflictNames: locationOfConflictNameList.join(', '),
                    locationOfConflictIds
                }
            })
            .filter(agreement => (
                    (
                        Boolean(countryIdFilter)
                            ? (
                                countryIdFilter === -1
                                    ? agreement.locationOfConflictIds.length === 0
                                    : agreement.locationOfConflictIds.indexOf(countryIdFilter) !== -1
                            )
                            : true
                    )
                &&  (
                        Boolean(dateFilter)
                            ? (agreement.date || '').split('T')[0] === dateFilter
                            : true
                    )
                &&  (
                        Boolean(titleFilter)
                        ? (agreement.title || '').toLowerCase().includes(titleFilter.toLowerCase())
                        : true
                    )
            ))

        return (
            <div>
                <TopBar>
                    <SearchForm onSubmit={ (e: Event, values: any) => this.handleSubmitSearch(e, values) }/>
                </TopBar>

                <div className="lop-main-content">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <h1>Peace Agreements</h1>
                            </div>
                            <div className="col-auto">
                                <Link onClick={ e => this.handleClickAddAgreement() } to="/agreement/new" className="lop-btn">
                                    <img className="lop-btn-icon" src={ plusIcon }/>
                                    Add Agreement
                                </Link>
                            </div>
                        </div>
                        <AgreementList
                            data={ filteredAgreementList }
                            highlightedIds={ focusedAgreements }
                            loading={ loading }
                            onChange={ (newAgreementList: any[], changedAgreement: any[], deleted: boolean) => this.handleChangeAgreementList(newAgreementList, changedAgreement, deleted) }
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapActionsToProps
)(Home)
