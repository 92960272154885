import React from 'react';
import { connect } from 'react-redux';
import DebugInfo from '../debug-info-list/debug-info-list.component';
import Multitext from '../multitext/multitext.component';
import './signee.styles.scss';

interface SigneeProps {
    onChange?(newData: any): void
    data: any,
    title?: string,
    highlight?: boolean,
    signeeTypeList: any[],
    countryList: any[],
    nonStateOrganizationList: any[],
    internationalOrganizationList: any[]
}

interface SigneeState {
    deleting: boolean
}

const initialState: SigneeState = {
    deleting: false
};

const mapStateToProps = (state: any) => {
    return {
        signeeTypeList: state.signeeTypeList.data,
        countryList: state.countryList.data,
        nonStateOrganizationList: state.nonStateOrganizationList.data,
        internationalOrganizationList: state.internationalOrganizationList.data
    }
};

class Signee extends React.Component<SigneeProps, SigneeState> {
    public state: SigneeState = { ...initialState }
    private multitextNewValues: any = {}

    private fireOnChange(newData: any) {
        if (this.props.onChange)
            this.props.onChange(newData)
    }

    private handleChange(valueName: string, value: any) {
        this.fireOnChange({
            ...this.props.data,
            [valueName]: value
        })
    }

    private handleTypeMultitext(keyName: string, text: string) {
        this.multitextNewValues[keyName] = text;
    }

    private handleChangeTypeOfSigneeId(value: any) {
        this.fireOnChange({
            ...this.props.data,
            typeCode: value,
            metadataId: null
        });
    }

    private handleClickRemove() {
        this.setState({
            deleting: true,
        });

        setTimeout(() => {
            this.setState({
                deleting: false
            })
            this.fireOnChange(null)
        }, 300);
    }

    render() {
        const {
            data,
            highlight,
            signeeTypeList,
            countryList,
            nonStateOrganizationList,
            internationalOrganizationList
        } = this.props;
        
        const typeOfSigneeOptions: any = {
            'country': countryList, 'country_witness': countryList,
            'non_state_org': nonStateOrganizationList, 'nso_witness': nonStateOrganizationList,
            'int_org_party': internationalOrganizationList, 'int_org_witness': internationalOrganizationList,
        };

        const { deleting } = this.state;

        return (
            <div className="lop-signee">
                <div className={ 'row lop-signee-animated' + (deleting ? ' lop-signee-deleting' : '') + (highlight ? ' lop-signee-highlight' : '') }>
                    <div className="col-12 lop-form-group">
                        <div className="lop-label">
                            <div className="lop-section-title">{ this.props.title }</div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-5 lop-form-group">
                        <label className="lop-label">
                            Signee Name
                            <input type="text" className="lop-input" value={ data.name || '' } onChange={ e => this.handleChange('name', e.target.value) }/>
                        </label>
                    </div>
                    <div className="col-12 col-lg-5 lop-form-group">
                        <label className="lop-label">
                            Signee Position
                            <input type="text" className="lop-input" value={ data.position || '' } onChange={ e => this.handleChange('position', e.target.value) }/>
                        </label>
                    </div>
                    <div className="col-12 col-lg-5 lop-form-group">
                        <label className="lop-label">
                            Type of Signee
                            <select className="lop-input" value={ data.typeCode } onChange={ e => this.handleChangeTypeOfSigneeId(e.target.value) }>
                                { !data.typeCode ? <option value="">...</option> : '' }
                                { 
                                    signeeTypeList.map((signeeType, index) =>
                                        <option key={ index } value={ signeeType.code }>
                                            { signeeType.name }
                                        </option>
                                    )
                                }
                            </select>
                        </label>
                    </div>
                    <div className="col-12 col-lg-5 lop-form-group">
                        { data.typeCode ? (
                            <div>
                                <div className="lop-label">
                                    Name of { signeeTypeList.filter(signeeTypeData => signeeTypeData.code === data.typeCode)[0].name }
                                </div>
                                <Multitext
                                    value={ data.metadataId }
                                    options={ typeOfSigneeOptions[String(data.typeCode)].map((countryData: any) => ({label: countryData.name, value: countryData.id})) }
                                    filter={ true }
                                    onChange={ (newValue: any) => this.handleChange('metadataId', newValue || null) }
                                    onType={ (text: string) => this.handleTypeMultitext('metadataId', text) }
                                    allowText
                                />
                            </div>
                        ) : (
                            <label className="lop-label">
                                Name
                                <input type="text" className="lop-input" disabled/>
                            </label>
                        ) }
                    </div>
                    <div className="col-12 col-lg-2 lop-form-group">
                        <button className="lop-btn lop-btn-outline lop-small" onClick={ e => this.handleClickRemove() }>Remove</button>
                    </div>
                    <DebugInfo name={ this.props.title } data={ data } collapsed/>
                </div>
                <hr/>
            </div>
        )
    }
}

export default connect(mapStateToProps, {})(Signee);