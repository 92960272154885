import React from 'react';
import './spinner.styles.scss';

const Spinner: React.FunctionComponent<{
    centered?: boolean,
    message?: string;
}> = ({
    centered,
    message
}) => (
    centered
        ? (
            <div className='centered-loader'>
                <div className="loader">Loading...</div>
                { (message !== undefined) && <div className='spinner-message'>{ message }</div> }
            </div>
        )
        : (
            <>
                <div className="loader">Loading...</div>
                { (message !== undefined) && <div className='spinner-message'>{ message }</div> }
            </>
        )
    
);

export default Spinner;