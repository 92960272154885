import React from 'react';
import ReactDOM from 'react-dom/client';
import './vendor/bootstrap-grid.min.css'
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store from './store';
import reportWebVitals from "./reportWebVitals";

const rootElement = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(rootElement);
root.render(
  <Provider store={ store }>
      <App />
  </Provider>,
);

reportWebVitals();
