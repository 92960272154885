import Cookies from 'js-cookie';
import {
    setAgreement,
    setAgreementList,
    setMetadata,
    setSession,
    setTags,
    unsetSession
} from './api.utils';
import { validatedAction } from './firebase';

interface AppResponse {
    appResponse: boolean;
    code: ResponseCode;
    data: any;
}

export type ResponseCode = (
    'success' | 'unknown-error' | 'expired-token' |
    'unauthorized-claims' | 'user-without-claims' |
    'user-not-found' | 'no-token-provided' | 
    'invalid-token' | 'invalid-user' |
    'object-not-found' | 'invalid-request-data'
);

const API_URL = process.env.REACT_APP_API_URL;

if (!API_URL) {
    throw new Error('No API url set.');
}

const sessionReCreckTolerancyInSeconds: number = 30;
let lastSessionValidationDateTime: Date | null = null;

export const getHeaders = (headers?: any) => {
    const appHeaders: any = {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + (Cookies.get('LOP_SESSION_TOKEN') || ''),
        'Content-Type': 'application/json'
    };

    if (headers) {
        Object.assign(appHeaders, headers);
        Object.keys(appHeaders).forEach(
            headerName => appHeaders[headerName] || delete appHeaders[headerName]
        );
    }

    return appHeaders;
};

export const getEndpoint = (path: string): string => `${API_URL}${path}`;

export const groupRequest = (arrayOfRequest: any) => new Promise((resolve, reject) => {
    let count = arrayOfRequest.length
    const arrayOfResponse: any[] = []

    if (!arrayOfRequest || !arrayOfRequest.length)
        return reject()

    arrayOfRequest.forEach((requestFn: any, i: number) => 
        requestFn().then(
            (response: any) => {
                count--
                arrayOfResponse[i] = response

                if (!count)
                    resolve(arrayOfResponse)
            },
            (error: any) => {
                count--
                arrayOfResponse[i] = error

                if (!count)
                    resolve(arrayOfResponse)
            }
        )
    )
});

const responseHandler = (response: any) => (
    response.json() as AppResponse
);

const apiGet = (path: string) => new Promise<AppResponse>((resolve, reject) => {
    fetch(getEndpoint(path), {
        method: 'GET',
        headers: getHeaders()
    })
    .then(responseHandler)
    .then(
        appResponse => {
            if (appResponse.code === 'success') {
                resolve(appResponse);
            }
            else {
                reject(appResponse);
            }
        }
    )
});

const apiPost = (path: string, body?: any) => new Promise<AppResponse>((resolve, reject) => {
    fetch(getEndpoint(path), {
        method: 'POST',
        headers: getHeaders(),
        body: body ? JSON.stringify(body) : undefined
    })
    .then(responseHandler)
    .then(
        appResponse => {
            if (appResponse.code === 'success') {
                resolve(appResponse);
            }
            else {
                reject(appResponse);
            }
        }
    )
});

const apiDelete = (path: string, body?: any) => new Promise<AppResponse>((resolve, reject) => {
    fetch(getEndpoint(path), {
        method: 'DELETE',
        headers: getHeaders(),
        body: body ? JSON.stringify(body) : undefined
    })
    .then(responseHandler)
    .then(
        appResponse => {
            if (appResponse.code === 'success') {
                resolve(appResponse);
            }
            else {
                reject(appResponse);
            }
        }
    )
});

export const getSession = (force?: boolean) => new Promise((resolve, reject) => {
    const currentDateTime = new Date();

    if (
        !force
        && lastSessionValidationDateTime
        && (((currentDateTime.getTime() - lastSessionValidationDateTime.getTime()) / 1000) < sessionReCreckTolerancyInSeconds)
    ) {
        resolve(undefined);
    }
    else {
        apiGet('session').then(
            appResponse => {
                lastSessionValidationDateTime = new Date();
    
                setSession({
                    userEmail: appResponse.data.userEmail,
                    userIsSuperAdmin: Boolean(appResponse.data.userClaims?.superAdmin),
                    userIsAdmin: Boolean(appResponse.data.userClaims?.admin),
                    userIsEditor: Boolean(appResponse.data.userClaims?.editor)
                });
                resolve(undefined);
            },
            error => {
                reject();
            }
        );
    }
});

export const getMetadata = () => new Promise((resolve, reject) => {
    validatedAction().then(
        () => {
            apiGet('metadata').then(
                appResponse => {
                    setMetadata(appResponse.data);
                    resolve(undefined);
                },
                reject
            );
        }
    );

});

export const getTags = () => new Promise((resolve, reject) => {
    validatedAction().then(
        () => {
            apiGet('tags').then(
                appResponse => {
                    setTags(appResponse.data);
                    resolve(undefined);
                },
                reject
            );
        }
    );
});

export const updateTags = (updates: any) => new Promise((resolve, reject) => {
    validatedAction().then(
        () => {
            apiPost('tags', updates).then(
                appResponse => {
                    resolve(undefined);
                },
                reject
            );
        }
    );
});

export const deleteTags = (deletions: any) => new Promise((resolve, reject) => {
    validatedAction().then(
        () => {
            apiDelete('tags', deletions).then(
                appResponse => {
                    resolve(undefined);
                },
                reject
            );
        }
    );
});

export const getAgreementList = () => new Promise((resolve, reject) => {
    validatedAction().then(
        () => {
            apiGet('agreements').then(
                appResponse => {
                    setAgreementList(appResponse.data);
                    resolve(undefined);
                },
                reject
            );
        }
    );
});

export const getAgreement = (id: number) => new Promise<any>((resolve, reject) => {
    validatedAction().then(
        () => {
            apiGet('agreements/' + String(id)).then(
                appResponse => {
                    const agreementData = setAgreement(appResponse.data);
                    resolve(agreementData);
                },
                reject
            );
        }
    );
});

export const updateAgreement = (id: any, agreementData: any) => new Promise<undefined>((resolve, reject) => {
    validatedAction().then(
        () => {
            apiPost('agreements/' + String(id), agreementData).then(
                appResponse => {
                    resolve(undefined);
                },
                reject
            );
        }
    );
});

export const createAgreement = (agreementData: any) => new Promise<number>((resolve, reject) => {
    validatedAction().then(
        () => {
            apiPost('agreements', agreementData).then(
                appResponse => {
                    resolve(Number(appResponse.data));
                },
                reject
            );
        }
    );
});

export const deleteAgreement = (id: number) => new Promise<undefined>((resolve, reject) => {
    validatedAction().then(
        () => {
            apiDelete('agreements/' + String(id)).then(
                appResponse => {
                    resolve(undefined);
                },
                reject
            );
        }
    );
});

export const publishAgreement = (id: number, setPublished: boolean) => new Promise<undefined>((resolve, reject) => {
    validatedAction().then(
        () => {
            apiPost(`agreements/${id}/${setPublished ? 'publish' : 'unpublish'}`).then(
                appResponse => {
                    resolve(undefined);
                },
                reject
            );
        }
    );
});

export const uploadPDF = (agreementId: number, fileBuffer: File) => new Promise<string>((resolve, reject) => {
    validatedAction().then(
        () => {
            const body: any = new FormData();
            body.append('file', fileBuffer);
            
            fetch(getEndpoint('agreements/' + agreementId + '/pdf'), {
                method: 'POST',
                headers: getHeaders({'Content-Type': false}),
                body
            })
                .then(responseHandler)
                .then(appResponse => resolve(appResponse.data as string))
                .catch(error => {
                    switch(error.status) {
                        case 415: return reject('Invalid PDF file');
                        case 404: return reject('Service not available');
                        default: return reject('Unknown error');
                    }
                })
        }
    );
});

export const uploadPdfFile = (
    agreementId: number,
    fileBuffer: File,
    metadataId?: number
) => new Promise<string>((resolve, reject) => {
    validatedAction().then(
        () => {
            const body = new FormData();

            body.append('agreementId', String(agreementId));
            if (metadataId) {
                body.append('metadataId', String(metadataId));
            }
            body.append('file', fileBuffer);
            
            fetch(getEndpoint('pdf'), {
                method: 'POST',
                headers: getHeaders({'Content-Type': false}),
                body
            })
                .then(responseHandler)
                .then(appResponse => resolve(appResponse.data as string))
                .catch(error => {
                    switch(error.status) {
                        case 415: return reject('Invalid PDF file');
                        case 404: return reject('Service not available');
                        default: return reject('Unknown error');
                    }
                })
        }
    );
});

export const deleteParagraphs = (ids: number[]) => new Promise((resolve, reject) => {
    validatedAction().then(
        () => {
            apiDelete('paragraphs', ids).then(
                resolve,
                reject
            );
        }
    );
});

export const refreshCache = () => new Promise((resolve, reject) => {
    validatedAction().then(
        () => {
            apiPost('cache/refresh').then(
                resolve,
                reject
            );
        }
    );
});
