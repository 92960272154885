import React from 'react';
import './message-bar.styles.scss';

type MessageBarType = 'message' | 'success' | 'danger' | 'error';

interface MessageBarProps {
    message?: string | null | false,
    type?: MessageBarType
}

let currentMessage: string = '';

const MessageBar: React.FunctionComponent<MessageBarProps> = props => {
    const {
        message,
        type
    } = props;

    if (message)
        currentMessage = message;

    const messageBarClassName = (): string => {
        return (
            'c-message-bar'
            + ((): string => {
                switch(type) {
                    case 'success': return ' c-message-bar-type-success';
                    case 'danger': return ' c-message-bar-type-danger';
                    case 'error': return ' c-message-bar-type-error';
                    default: return ' c-message-bar-type-message';
                }
            })()
            + (message ? '' : ' c-message-bar-empty')
        );
    };

    return (
        <div className={ messageBarClassName() }>
            <div className="container">
                { currentMessage }
            </div>
        </div>
    );
};

export default MessageBar;