import React from 'react';
import './character-counter.styles.scss';

interface CharacterCounterProps {
    limit: number,
    text: string
}

const CaracterCounter: React.FC<CharacterCounterProps> = ({ limit, text }) => {
    const remainingCharacters: number = limit - text.length;

    return (
        <div className="lop-character-counter">
            { remainingCharacters } { remainingCharacters === 1 ? 'character' : 'characters' } left
        </div>
    )
};

export default CaracterCounter;