import store from './store';
import { TYPES } from './store/actions';
import hardcodedData from './hardcoded-data.json';
import { SessionStatePayload } from './store/types';

const signee_types = hardcodedData.signee_types;

export const setSession = (payload: Omit<SessionStatePayload, 'ready' | 'loggedIn' | 'userId' | 'dataLoaded'>): void => {
    store.dispatch({
        type: TYPES.SET_SESSION_VALUES,
        payload: {
            ready: true,
            loggedIn: true,
            userId: null,
            userEmail: payload.userEmail,
            userIsSuperAdmin: payload.userIsSuperAdmin,
            userIsAdmin: payload.userIsAdmin,
            userIsEditor: payload.userIsEditor
        }
    });
};

export const unsetSession = (payload?: undefined): void => {
    store.dispatch({
        type: TYPES.SET_SESSION_VALUES,
        payload: {
            ready: true,
            loggedIn: false,
            dataLoaded: false,
            userId: null,
            userEmail: null,
            userIsSuperAdmin: false,
            userIsAdmin: false,
            userIsEditor: false
        }
    });
};

export const setMetadata = (meta: any): void => {
    store.dispatch({
        type: TYPES.SET_LANGUAGE_LIST,
        payload: meta.languages
    })

    store.dispatch({
        type: TYPES.SET_CONFLICT_TYPE_LIST,
        payload: meta.conflict_types
    })

    store.dispatch({
        type: TYPES.SET_REGION_LIST,
        payload: meta.regions
    })

    store.dispatch({
        type: TYPES.SET_STATUS_LIST,
        payload: meta.status
    })

    store.dispatch({
        type: TYPES.SET_SIGNEE_TYPE_LIST,
        payload: meta.signee_types || signee_types
    })

    store.dispatch({
        type: TYPES.SET_COUNTRY_LIST,
        payload: meta.countries
    })

    store.dispatch({
        type: TYPES.SET_NON_STATE_ORGANIZATION_LIST,
        payload: meta.non_state_organizations
    })

    store.dispatch({
        type: TYPES.SET_INTERNATIONAL_ORGANIZATION_LIST,
        payload: meta.international_organizations || []
    })

    store.dispatch({
        type: TYPES.SET_TRANSLATION_LIST,
        payload: meta.original_translation || []
    })

    store.dispatch({
        type: TYPES.SET_LOCATION_OF_CONFLICT_LIST,
        payload: meta.locations_of_conflict || []
    })

    store.dispatch({
        type: TYPES.SET_TYPE_OF_INSTRUMENT_LIST,
        payload: meta.type_of_instrument || []
    })
};

export const setTags = (data: any): void => {
    const tagsDict: any = {}
    const tagsTree: any[] = []

    if (data) {
        data.forEach((tag: any) => {
            tag.childrenTags = []
            tag.previousTag = null
            tag.nextTag = null
            tagsDict[tag.id] = tag
        })

        data.forEach((tag: any) => {
            if (tag.parentTagId && tagsDict[tag.parentTagId]) {
                tag.parentTag = tagsDict[tag.parentTagId]
                tagsDict[tag.parentTagId].childrenTags.push(tag)
            }
            else {
                tag.parentTagId = null
                tag.parentTag = null
            }
        })

        data.forEach((tag: any) => {
            tag.childrenTags.sort((a: any, b: any) => a.weight - b.weight);
            tag.childrenTags.forEach((childTag: any, index: number) => {
                childTag.previousTag = tag.childrenTags[index - 1] || null
                childTag.nextTag = tag.childrenTags[index + 1] || null
            })

            if (!tag.parentTagId) {
                tagsTree.push(tag)
            }
        })

        tagsTree.sort((a, b) => a.weight - b.weight);

        tagsTree.forEach((tag, index) => {
            tag.previousTag = tagsTree[index - 1] || null;
            tag.nextTag = tagsTree[index + 1] || null;
        })
    }

    store.dispatch({
        type: TYPES.SET_TAG_LIST,
        payload: tagsTree
    })
};

export const setAgreementList = (data: any): void => {
    store.dispatch({
        type: TYPES.SET_AGREEMENT_LIST,
        payload: data
    })
};

export const setAgreement = (data: any): any => {
    store.dispatch({
        type: TYPES.SET_AGREEMENT,
        payload: data
    });

    return JSON.parse(JSON.stringify(data));
};
