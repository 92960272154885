import React from 'react';
import './section.styles.scss';

export type SectionDesign = 'default' | 'white' | 'white-box';

interface SectionProps {
    design?: SectionDesign,
    children?: React.ReactNode
}

const Section: React.FC<SectionProps> = ({
    design = 'default',
    children
}) => {
    const sectionClasses = ['lop-section'];

    switch (design) {
        case 'white-box': sectionClasses.push('lop-section-white-box'); break;
        case 'white': sectionClasses.push('lop-section-white'); break;
    }

    return (
        <div className={ sectionClasses.join(' ') }>
            <div className='container'>
                <div className='lop-section-box'>
                    <div className='lop-section-inner'>
                        { children }
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Section;
