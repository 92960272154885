import React from 'react';
import './text-editor.styles.scss';

interface TextEditorProps {
    onChange?(text: string): void,
    onSplit?(splittedText: string[]): void,
    value: string
}

const validTags = ['DIV', 'SPAN', 'BR'];
const maxFlatTagsLoop = 10;
let flatTagsLoop = 0;

const isInvalidTag = (tagName: string) => validTags.indexOf(tagName) === -1;

const removeTags = (tagName: string, parentElement: HTMLElement) => {
    Array.prototype.forEach.call(
        parentElement.getElementsByTagName(tagName),
        styleEl => styleEl.remove()
    );
};

const renameTag = (element: HTMLElement) => {
	let tagName = element.tagName.toLowerCase()
    let newHTML = element.outerHTML.replace(
        new RegExp('<' + tagName, 'g'), '<div'
    );
    newHTML = newHTML.replace(
        new RegExp('</' + tagName + '>', 'g'), '</div>'
    );

    element.outerHTML = newHTML;
}

const flatTags = (element: HTMLElement) => {
	flatTagsLoop++
        
	Array.prototype.forEach.call(
    	element.querySelectorAll('*'),
        child => {
        	if (isInvalidTag(child.tagName))
            	renameTag(child)
        }
    )
    
    let childrenLeft = 0
	Array.prototype.forEach.call(
    	element.querySelectorAll('*'),
        child => {
        	if (isInvalidTag(child.tagName))
            	childrenLeft++
        }
    )
    
    if (childrenLeft && (flatTagsLoop <= maxFlatTagsLoop))
    	flatTags(element)
        
    else
    	flatTagsLoop = 0
}

const removeAttributes = (attrNames: string[], parentElement: HTMLElement) => {
	attrNames.forEach(attrName => {
    	Array.prototype.forEach.call(
        	parentElement.querySelectorAll('[' + attrName + ']'),
            el => el.removeAttribute(attrName)
        )
    })
}

const fixUnwrappedText =  (element: HTMLElement) => {
	Array.prototype.forEach.call(
    	element.childNodes,
        child => {
        	if (child.nodeType === 3) {
                let wrap = document.createElement('div')
                element.insertBefore(wrap, child)
                wrap.appendChild(child)
            }
            	
        }
    )
}

const renderContextOptions = (options: any[], coords = [15, 15]) => {
    const list = document.createElement('ul');
    const closeEvent = (e: any) => {
        const { target } = e;

        if ((target !== list) && (target.parentNode !== list))
            closeContextOptions()
    };
    const closeContextOptions = () => {
        document.removeEventListener('mousedown', closeEvent)
        list.remove()
    };

    list.className = 'fn-context-options';

    options.forEach(optionData => {
        const element = document.createElement('li')
        element.onclick = e => {
            optionData.action(e)
            closeContextOptions()
        }
        element.innerHTML = optionData.name
        list.appendChild(element)
    })

    list.style.left = (coords[0] + 2) + 'px'
    list.style.top = (coords[1] + 2) + 'px'
    
    document.addEventListener('mousedown', closeEvent);
    document.body.appendChild(list);
}

const splitText = (element: HTMLElement) => {
    if (window.getSelection) {
        let selection = window.getSelection();

        if (!selection || !element.firstChild) return;
        
        let selectionText = selection.toString();
        
        if (selection.rangeCount) {
            let range = selection.getRangeAt(0);
            let precedingRange = document.createRange();

            precedingRange.setStartBefore(element.firstChild);
            precedingRange.setEnd(range.startContainer, range.startOffset);

            let textPrecedingSelection = precedingRange.toString();
            let wordIndex = textPrecedingSelection.split(/\s+/).length - 2;
            let fullText = element.innerText;
            let currentWordIndex = -1;
            let previousWords: string[] = [];
            let selectionFirstMatching: string[] = [];
          
          
            fullText.split(' ').filter(word => !!word).forEach((word, i) => {
                let isPrev = true

                word.split('\n').forEach(wordInWord => {
                    currentWordIndex++

                    if ((currentWordIndex >= wordIndex) && isPrev)
                        isPrev = false
                })

                if (isPrev)
                    previousWords.push(word)

                else
                    selectionFirstMatching.push(word)
            })
          
            let splittedContent = selectionFirstMatching.join(' ').split(selectionText)
            let previousContent = [...previousWords, splittedContent.shift()].join(' ')
            let nextContent = splittedContent.join(selectionText)
          
            return [previousContent, selectionText, nextContent]
        }
    }
}

const getPlainText = (text: string): HTMLParagraphElement[] =>
    text.split('\n').map(paragraph => (pElement => {
        pElement.appendChild(document.createTextNode(paragraph))
        return pElement
    })(document.createElement('p')))

export default class TextEditor extends React.Component<TextEditorProps, {}> {
    private element: any = React.createRef();
    private paragraphTagName: string = 'div';

    constructor(props: TextEditorProps) {
        super(props);
        this.documentMouseUpEvent = this.documentMouseUpEvent.bind(this)
    }

    private getParagraphsAsString(rawText: string): string {
        return rawText.split('\n').filter(line => line && line.trim().length).map(line => `<${this.paragraphTagName}>` + line + `</${this.paragraphTagName}>`).join('')
    }

    documentMouseUpEvent(e: MouseEvent) {
        const self = this;
        const element: any = self.element.current;
        const splittedContent: string[] = splitText(element) || [];
    
        if (
            (document.activeElement === element)
            && (
                splittedContent[1] &&  splittedContent[1].length
            )
            && (
                splittedContent[0].length
                || (
                    splittedContent[2] && splittedContent[2].length
                )
            )
        )
            renderContextOptions(
                [{
                    name: 'Separate paragraph',
                    action: (e: MouseEvent) => {
                        e.preventDefault()
                        self.fireOnSplit(splittedContent)
                    }
                }],
                [e.pageX, e.pageY]
            );
    }

    componentDidMount() {
        const element: any = this.element.current
        const self = this

        element.innerHTML = this.getParagraphsAsString(this.props.value || '');

        element.onpaste = (e: ClipboardEvent) => {
            setTimeout(() => {
                element.innerHTML = this.getParagraphsAsString(element.innerText)
            }, 0)
        };
        
        element.oninput = () => {
            self.fireOnChange(element.innerText)
        }

        document.addEventListener('mouseup', this.documentMouseUpEvent)
        element.focus()
    }

    componentDidUpdate() {
        // console.log({
        //     newValue: this.props.value
        // })
    }

    componentWillUnmount() {
        document.removeEventListener('mouseup', this.documentMouseUpEvent)
    }

    fireOnChange(newText: string) {
        if (this.props.onChange)
            this.props.onChange(newText)
    }

    fireOnSplit(splittedContent: string[]) {
        if (this.props.onSplit)
            this.props.onSplit(splittedContent)
    }

    render() {
        const isEmpty = !this.props.value.replace(new RegExp('\n', 'g'), '').length

        return (
            <div className={ 'editor text-editor' + (isEmpty ? ' empty' : '') } contentEditable="true" ref={ this.element }></div>
        );
    }
}