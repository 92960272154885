import React from 'react';
import './icon.styles.scss';

interface IconProps {
    src: string,
    hover?: string,
    alt?: string,
    x3?: boolean,
    x2?: boolean
}

const Icon: React.FunctionComponent<IconProps> = props => {
    const { src, hover, alt = 'Image', x3, x2 } = props;

    function checkSize(element: HTMLImageElement) {
        if (!element) return
    
        setTimeout(() => {
            if (x3) element.height = element.naturalHeight / 3;
            else if (x2) element.height = element.naturalHeight / 2;

        }, element.height ? 0 : 50);
    }

    return (
        <span className="c-icon">
            <img className="c-icon-normal" src={ src } alt={ alt } ref={ checkSize }/>
            { hover ? 
                <img className="c-icon-hover" src={ hover } alt={ alt } ref={ checkSize }/>
                : '' 
            }
        </span>
    )
};

export default Icon;