import { TYPES } from '../actions'

const defaultState = {
    data: {
        pdfUrl: '',
        createdAt: '',
        date: '',
        id: null,
        metadata: [],
        metadataIds: [],
        paragraphs: [],
        published: false,
        relatedAgreements: [],
        relatedAgreementIds: [],
        signees: [],
        title: '',
        updatedAt: '',
        pdfFiles: []
    },
    updatedAt: null
}

export default (state = defaultState, { type, payload }) => {
    switch(type) {
        case TYPES.SET_AGREEMENT:
            return {
                data: {...payload},
                updatedAt: Date.now()
            }

        case TYPES.CLEAR_AGREEMENT_DATA:
            return {...defaultState}

        default:
            return state
    }
}