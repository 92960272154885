import React from 'react';
import AgreementListItem from  '../agreement-list-item/agreement-list-item.component';
import Spinner from '../../components/spinner/spinner.component';
import Paginator from '../paginator/paginator.component';
import { FaSortDown, FaSortUp, FaThumbsDown } from "react-icons/fa";
import './agreement-list.styles.scss';

interface AgreementListProps {
    onChange?(list: any[], changedElement: any, deleted: boolean): void,
    data: any[],
    loading: boolean,
    highlightedIds: any[]
}

interface AgreementListState {
    perPage: number,
    page: number,
    sortCriteria: SortCriteria,
    sortReverse: boolean
}

type SortCriteria = 'title' | 'locationOfConflictName' | 'date' | 'published' | 'updatedAt';

const initialState: AgreementListState = {
    perPage: 100,
    page: 1,
    sortCriteria: 'updatedAt',
    sortReverse: false
}

class AgreementList extends React.Component<AgreementListProps, AgreementListState> {
    public state = {
        ...initialState
    }

    public componentDidMount() {
        // getAgreementList();
    }

    private sortIcon(criteriaName: string) {
        return (
            criteriaName === this.state.sortCriteria ? (
                this.state.sortReverse ? <FaSortDown /> : <span className="lop-sort-up"><FaSortUp /></span>
            ) : ''
        )
    }

    applySort(data: any[]) {
        data = [...data]

        data.sort((a, b) => {
            if (a[this.state.sortCriteria] < b[this.state.sortCriteria])
                return -1;
            
            if (a[this.state.sortCriteria] > b[this.state.sortCriteria])
                return 1;
              
            return 0
        })

        if (this.state.sortReverse)
            data.reverse()

        return data
    }

    handleChangeSort(sortCriteria: SortCriteria) {
        this.setState({
            sortCriteria,
            sortReverse: this.state.sortCriteria === sortCriteria ? !this.state.sortReverse : this.state.sortReverse
        });
    }

    handleChangePage(page: number) {
        this.setState({ page })
    }

    handleChangeAgreementData(newAgreementData: any, index: number) {
        index = index + ((this.state.page - 1) * this.state.perPage)

        if (this.props.onChange)
            this.props.onChange(
                this.props.data.map((agreementData, i) => i === index ? newAgreementData : agreementData),
                newAgreementData,
                false
            )
    }

    handleDeleteAgreement(deletedAgreement: any) {
        if (this.props.onChange)
            this.props.onChange(
                this.props.data.filter(agreementData => agreementData.id !== deletedAgreement.id),
                deletedAgreement,
                true
            )
    }

    render() {
        const { data, loading, highlightedIds } = this.props
        const { page, perPage } = this.state
        const totalPages = data ? Math.ceil(data.length / perPage) : 1
        const firstIndex = perPage * (page - 1)
        const lastIndex = firstIndex + perPage - 1

        return (
            <div className="lop-agreements asd">
                <ul className="lop-agreement-list-header">
                    <li className="lop-agreement-list-head lop-clickable lop-agreement-title" onClick={ e => this.handleChangeSort('title') }>
                        Title { this.sortIcon('title') }
                    </li>
                    <li className="lop-agreement-list-head lop-clickable lop-agreement-country" onClick={ e => this.handleChangeSort('locationOfConflictName') }>
                        Country { this.sortIcon('locationOfConflictName') }
                    </li>
                    <li className="lop-agreement-list-head lop-clickable lop-agreement-date" onClick={ e => this.handleChangeSort('date') }>
                        Date { this.sortIcon('date') }
                    </li>
                    <li className="lop-agreement-list-head lop-clickable lop-agreement-published" onClick={ e => this.handleChangeSort('published') }>
                        Published { this.sortIcon('published') }
                    </li>
                    <li className="lop-agreement-list-head lop-clickable lop-agreement-updated" onClick={ e => this.handleChangeSort('updatedAt') }>
                        Updated { this.sortIcon('updatedAt') }
                    </li>
                    <li className="lop-agreement-list-head lop-agreement-actions"></li>
                </ul>
                <ul className="lop-agreement-list">
                    { loading ? 
                        <li><Spinner/></li>
                        :
                        (data && data.length ?
                            this.applySort(data).filter((agreement, i) => (i >= firstIndex) && (i <= lastIndex)).map((agreement, index) => (
                                <li key={ index } className={ highlightedIds.indexOf(agreement.id) === -1 ? '' : 'highlight' }>
                                    <AgreementListItem
                                        agreement={ agreement }
                                        onChange={ (newAgreementData: any) => this.handleChangeAgreementData(newAgreementData, index) }
                                        onDelete={ (deletedAgreement: any) => this.handleDeleteAgreement(deletedAgreement) }
                                    />
                                </li>
                            ))
                            :
                            <li>No agreements</li>
                        )
                    }
                </ul>
                <div className="lop-page-bottom lop-text-center">
                    <Paginator currentPage={ page } lastPage={ totalPages } onChange={ (newPage: number) => this.handleChangePage(newPage) }/>
                </div>
            </div>
        )
    }
}

export default AgreementList