import React from 'react';
import './paginator.styles.scss';
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

interface PaginatorProps {
    onChange?(selectedPage: number): void,
    currentPage: number,
    lastPage: number
}

function iterableNumber(n: number): number[] {
    let current: number = 1;
    const response: number[] = [];

    while(current <= n) {
        response.push(current);
        current++;
    }

    return response;
}

const Paginator: React.FunctionComponent<PaginatorProps> = props => {
    const maxNavButtons: number = 8;
    const { currentPage, lastPage, onChange } = props;

    function handleChangePage(value: string | number) {
        if (onChange) onChange(Number(value))
    }

    function navButtons() {
        let content = []
        let leftButtons = (maxNavButtons / 2)
        let rightButtons = maxNavButtons - leftButtons
        let currentIteration = 1

        if ((currentPage - leftButtons) < 1) {
            rightButtons-= (currentPage - leftButtons - 1)
            leftButtons = maxNavButtons - rightButtons
        }

        if ((currentPage + rightButtons) > lastPage) {
            leftButtons-=  lastPage - (currentPage + rightButtons)
            rightButtons = maxNavButtons - leftButtons
        }

        while (currentIteration <= lastPage) {
            content.push(
                currentPage === currentIteration ? (
                    <span key={ currentIteration } className="c-paginator-btn c-paginator-active">
                        { currentIteration }
                    </span>
                ) : (
                    (currentIteration < currentPage) && (currentIteration >= (currentPage - leftButtons)) ? (
                        <a key={ currentIteration } className="c-paginator-btn" data-value={ currentIteration }
                        onClick={ (e: any) => { e.preventDefault(); handleChangePage(e.target.getAttribute('data-value')) } }>
                            { currentIteration }
                        </a>
                    ) : (
                        (currentIteration > currentPage) && (currentIteration <= (currentPage + rightButtons)) ? (
                            <a key={ currentIteration } className="c-paginator-btn" data-value={ currentIteration }
                            onClick={ (e: any) => { e.preventDefault(); handleChangePage(e.target.getAttribute('data-value')) } }>
                                { currentIteration }
                            </a>
                        ) : (
                            <span key={ currentIteration } className="c-paginator-dots">...</span>
                        )
                    )
                )
            )

            currentIteration++
        }

        if ((currentPage - leftButtons) > 1) {
            content.unshift(
                <a key={ 0 } className="c-paginator-btn" onClick={ e => handleChangePage(1) }>
                    1
                </a>
            )
            content = content.filter((c, i) => i !== (currentPage - leftButtons))
        }

        if ((currentPage + rightButtons) < lastPage) {
            content.push(
                <a key={ lastPage + 1 } className="c-paginator-btn" onClick={ e => handleChangePage(lastPage) }>
                    { lastPage }
                </a>
            )
            content = content.filter((c, i) => i !== (currentPage + rightButtons - 1))
        }

        return content
    }


    return (
        <div className="c-paginator">
            {
                currentPage > 1 ? (
                    <a className="c-paginator-btn c-paginator-previous" onClick={ e => { e.preventDefault(); handleChangePage(Math.max(currentPage - 1, 1)) } }>
                        <FaAngleLeft/>
                    </a>
                ) : ''
            }
            { navButtons() }
            {
                currentPage < lastPage ? (
                    <a className="c-paginator-btn c-paginator-next" onClick={ e => { e.preventDefault(); handleChangePage(Math.min(currentPage + 1, lastPage)) } }>
                        <FaAngleRight/>
                    </a>
                ) : ''
            }
            <div className="c-paginator-menu">
                <select className="c-paginator-options" value={ currentPage } onChange={ e => handleChangePage(e.target.value) }>
                    { iterableNumber(lastPage).map(n => 
                        <option key={ n } value={ n }>{ n }</option>
                    ) }
                </select>
                <a className="c-paginator-btn">
                    &#183;&#183;&#183;
                </a>
            </div>
        </div>
    );
};

export default Paginator;

class PaginatorOld extends React.Component {
    






}