import React from 'react';
import './footer.styles.scss';

const Footer: React.FunctionComponent<{}> = () => {
    return (
        <div className="lop-footer-container">
            <footer className="lop-footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-6">©2019 United Nations, DPPA</div>
                        <div className="col-6" style={ {textAlign: 'right'} }>Site by: PASTPRESENTFUTURE</div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Footer;