import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import Cookies from 'js-cookie';
import store from './store';
import { TYPES } from './store/actions';
import { getAgreementList, getMetadata, getSession, getTags, groupRequest } from './api.v3';
import { unsetSession } from './api.utils';

const config = {
    apiKey: "AIzaSyA7gD0LlNie_vd7lQMDWG-T8DutyRtt7D0",
    authDomain: "language-of-peace.firebaseapp.com",
    databaseURL: "https://language-of-peace.firebaseio.com",
    projectId: "language-of-peace",
    storageBucket: "language-of-peace.appspot.com",
    messagingSenderId: "10209535144",
    appId: "1:10209535144:web:4431c0bb6b0f57fee3ce9d",
    measurementId: "G-9QFRPZ0GRJ"
};

firebase.initializeApp(config);

const auth = firebase.auth();

auth.onAuthStateChanged((user) => {
    if (user) {
        refreshToken().then(
            () => {
                getSession().then(
                    () => {
                        groupRequest([getMetadata, getTags, getAgreementList]).then(
                            () => {
                                store.dispatch({
                                    type: TYPES.SET_SESSION_VALUES,
                                    payload: {
                                        dataLoaded: true
                                    }
                                });
                            },
                            () => {}
                        );
                    },
                    () => {}
                );
            },
            () => {}
        );
    }
    else {
        destroyToken();
        unsetSession();
    }
});

export const login = (email: string, password: string) => new Promise((resolve, reject) => {
    auth.signInWithEmailAndPassword(email, password).then(
        resolve,
        reject
    )
});

export const logout = () => new Promise((resolve, reject) => {
    auth.signOut().then(
        resolve,
        reject
    )
});

export const refreshToken = () => new Promise((resolve, reject) => {
    if (auth.currentUser) {
        auth.currentUser.getIdToken().then(
            (idToken: any) => {
                setToken(idToken);
                resolve(true);
            },
            reject
        )
    }
    else {
        reject();
    }
});

export const destroyToken = () => {
    Cookies.remove('LOP_SESSION_TOKEN');
}

export const setToken = (token: string) => {
    if (process.env.REACT_APP_DEBUG_MODE === 'true') {
        console.log('Debug: Your session token is:', token);
    }
    
    return Cookies.set('LOP_SESSION_TOKEN', token);
}

export const getToken = () => {
    return Cookies.get('LOP_SESSION_TOKEN');
};

export const validatedAction = () => new Promise<boolean>((resolve, reject) => {
    const { currentUser } = auth;

    if (currentUser) {
        getSession().then(
            () => {
                resolve(true);
            },
            () => {
                refreshToken().then(
                    () => {
                        resolve(true);
                    },
                    reject
                );
            }
        )
    }
    else {
        destroyToken();
        unsetSession();

        return reject({
            message: 'No user'
        });
    }
})

export default firebase;
