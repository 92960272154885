import React from 'react';
import { BrowserRouter as Router, Route, Navigate, Routes } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from './firebase';
import './assets/bootstrap-custom.min.css';
import './App.scss';
import Footer from './components/footer/footer.component';
import Spinner from './components/spinner/spinner.component';
import { Home, Edit, EditTags, Login, Logout } from './pages';

const mapStateToProps = (state: any) => {
  return {
      loggedIn: state.session.loggedIn,
      dataLoaded: state.session.dataLoaded,
      userType: state.session.userType,
      sessionReady: state.session.ready
  }
}

interface AppState {
  fetching: any,
  error: any,
  showSearchForm: boolean
}

interface AppProps {
  dataLoaded: any,
  userType: any,
  loggedIn: any,
  sessionReady: any
}

class App extends React.Component<AppProps, AppState> {
  constructor(props: any) {
      super(props)

      this.state = {
          fetching: true,
          error: false,
          showSearchForm: true
      }
  }

  logoutTest() {
      logout()
  }

  loggedRoutes() {
      return (
          <div>
              { this.props.dataLoaded ? (
                  <>
                  {/* <Spinner centered message='dada'/> */}
                      <Routes>
                          <Route path="/" element={ <Home/> }/>
                          <Route path="/agreement/new" element={ <Edit/> }/>
                          <Route path="/agreement/:id" element={ <Edit/> }/>
                          <Route path="/tags" element={ <EditTags/> }/>
                          <Route path="/login" element={ <Navigate replace to="/"/> }/>
                          <Route path="/logout" element={ <Logout/> }/>
                      </Routes>
                      <Footer/>
                  </>
              ) : <Spinner centered message='Fetching data'/> }
          </div>
      )
  }

  notLoggedRoutes() {
      return (
          <Routes>
              <Route path="/login" element={ <Login/> }/>
              <Route path="/logout" element={ <Logout/> }/>
              <Route path="/*" element={ <Navigate replace to="/login"/> }/>
          </Routes>
      )
  }

  render() {
      const { sessionReady, loggedIn } = this.props;

      return (
          <div className="app-wrapper">
              { sessionReady ? (
                  <Router>
                      { loggedIn ? (
                          this.loggedRoutes()
                      ) : (
                          this.notLoggedRoutes()
                      ) }
                  </Router>
              ) : <Spinner centered message=''/> }

          </div>
      )
  }
}

export default connect(mapStateToProps)(App);