import { TYPES } from '../actions'

const defaultState = {
    token: null,
    displayName: null,
    email: null,
    userType: null,
    loggedInAt: null,
    superAdmin: false,
    admin: false,
    editor: false,

    ready: false,
    loggedIn: false,
    userId: null,
    userEmail: null,
    userIsSuperAdmin: false,
    userIsAdmin: false,
    userIsEditor: false,
    dataLoaded: false
}

export default (state = defaultState, { type, payload }) => {
    switch(type) {
        case TYPES.SET_SESSION_VALUES:
            return {
                ...state,
                ...payload
            };

        case TYPES.SET_SESSION_USER:
            return {
                ...state,
                loggedIn: true,
                userId: payload.userId,
                userEmail: payload.userEmail,
                userIsSuperAdmin: payload.userIsSuperAdmin,
                userIsAdmin: payload.userIsAdmin,
                userIsEditor: payload.userIsEditor
            };

        case TYPES.SET_SESSION_TOKEN:
            return {
                ...state,
                ...payload,
                userType: 'authenticated',
                loggedIn: true,
                loggedInAt: Date.now()
            }

        case TYPES.UNSET_SESSION_TOKEN:
            return {
                ...state,
                token: null,
                userType: 'anonymous',
                loggedIn: false,
                loggedInAt: null
            }

        case TYPES.SESSION_HAS_DATA:
            return {
                ...state,
                dataLoaded: true
            }

        case TYPES.SESSION_HAS_NOT_DATA:
            return {
                ...state,
                dataLoaded: false
            }

        case TYPES.SESSION_SET_CLAIMS:
            return {
                ...state,
                superAdmin: Boolean(payload.superAdmin),
                admin: Boolean(payload.admin),
                editor: Boolean(payload.editor)
            }

        default:
            return state
    }
}