import React from 'react';
import { NavLink } from 'react-router-dom';
import './menu.styles.scss';
import menuIcon from './menu.icon.svg';
import menuCloseIcon from './menu-close.icon.svg';
import logoLanguageOfPeace from '../../assets/img/lop-logo@3x.png';
import MessageBar from '../message-bar/message-bar.component';
import { getEndpoint, refreshCache } from '../../api.v3';

interface MenuProps {
    username?: string,
    superAdmin?: boolean
}

interface MenuState {
    open: boolean
    message: string | null
    messageType: MessageType
}

interface MenuOptionProps {
    to: string,
    onClick?: any
}

type MessageType = 'message' | 'success' | 'danger' | 'error';

const initialState: MenuState = {
    open: false,
    message: null,
    messageType: 'message'
}

export default class Menu extends React.PureComponent<MenuProps, MenuState> {
    constructor(props: MenuProps) {
        super(props);
    }

    public state = {
        ...initialState
    }

    private renderMenuOption(name: string, link: string, color?: string | null, downloadFileName?: string) {
        return (
            <li key={ name }>
                <NavLink
                    to={ link }
                    onClick={ e => this.close() }
                    className={ ({isActive}) => isActive ? 'lop-menu-active' : '' }
                    style={ ({isActive}) => {
                        const styleProps: React.CSSProperties = color ? {color} : {};
                        const activeStyleProps: React.CSSProperties = isActive ? {borderColor: color || 'none'} : {};
                        return {...styleProps, ...activeStyleProps}
                    } }
                    end={ true }
                    download={ downloadFileName }>
                        { name }
                </NavLink>
            </li>
        );
    }

    private renderExternalLink(name: string, link: string, downloadFileName?: string) {
        return (
            <li key={ name }>
                <a
                    href={ link }
                    onClick={ e => this.close() }
                    target="_blank"
                    download={ downloadFileName }>
                        { name }
                </a>
            </li>
        );
    }

    private renderAPILink(name: string, url: string) {
        return (
            <li key={ name }>
                <a
                    href={ getEndpoint(url) }
                    onClick={ e => this.close() }
                    target="_blank">
                        { name }
                </a>
            </li>
        )
    }

    private open() {
        document.body.style.overflow = 'hidden';
        this.setState({ open: true });
    }

    private close() {
        document.body.style.overflow = 'auto';
        this.setState({ open: false });
    }

    private refreshCache() {
        this.showMessage('Updating caches...', 'message')

        refreshCache().then(
            () => this.showMessage('Caches updated successfully', 'success', 8000),
            err => err.status === 401
                ? this.showMessage('You are not authorized to make this request', 'danger', 8000)
                : this.showMessage('Error updating caches', 'error', 8000)
        );

        this.close();
    }

    private showMessage(message: string | null, messageType: MessageType = 'message', timeout: number = 0) {
        this.setState({
            message,
            messageType
        });

        if (timeout > 0) {
            setTimeout(() => {
                this.setState({
                    message: null,
                    messageType: 'message'
                });
            }, timeout)
        }
    }

    render() {
        const { open, message, messageType } = this.state;
        const { username } = this.props;


        return (
            <div className="lop-menu">
                <button type="button" onClick={ () => open ? this.close() : this.open() }>
                    <img src={ menuIcon }/>
                </button>
                <div
                    className={ 'lop-menu-list-blocker' + (open ? ' lop-blocker-on' : ' lop-blocker-off') }
                    onClick={ () => this.close() }/>
                <div className={ 'lop-menu-list-wrapper' + (open ? ' lop-menu-open' : ' lop-menu-closed') }>
                    <div className="lop-menu-list-head">
                        <div className="row">
                            <div className="col lop-menu-logo">
                                <img src={ logoLanguageOfPeace }/>
                            </div>
                            <div className="col-auto">
                                <button type="button" className="lop-menu-close-btn" onClick={ () => this.close() }>
                                    <img src={ menuCloseIcon } width="40%"/>
                                </button>    
                            </div>
                        </div>
                    </div>
                    <div className="lop-menu-body">
                        <ul className="lop-menu-list">
                            { [
                                this.renderMenuOption('Home', '/'),
                                this.renderMenuOption('New agreement', '/agreement/new'),
                                this.props.superAdmin && this.renderMenuOption('Edit tags', '/tags'),
                                this.renderAPILink('Download CSV', 'cache/agreements.csv?download')
                            ] }
                            { this.props.superAdmin && (
                                <li>
                                    <a onClick={ e => this.refreshCache() } style={ message ? {opacity: '.4', pointerEvents: 'none'} : {cursor: 'pointer'} }>
                                        Update caches
                                        { message && ' (Updating...)' }
                                    </a>
                                </li>
                            ) }
                        </ul>
                    </div>
                    <div className="lop-menu-footer">
                        { username ? (
                            <div className="lop-menu-list-content">
                                Current user: <strong>{ username }</strong>.
                            </div>
                        ) : null}

                        <ul className="lop-menu-list">
                            { [
                                this.renderMenuOption('Logout', '/logout', '#de2424')
                            ] }
                        </ul>
                    </div>
                </div>
                <MessageBar message={ message } type={ messageType }/>
            </div>
        );
    }
}

// export default Menu;