import React from 'react';
import './radio.styles.scss';

interface RadioProps {
    value?: any,
    checked?: boolean,
    onChange?(event: any): void,
    children?: React.ReactNode
}

const Radio: React.FunctionComponent<RadioProps> = props => {
    return (
        <div className="lop-radio">
            <label>
                <input type="radio" className="lop-radio-core"
                value={ props.value }
                checked={ props.checked }
                onChange={ props.onChange }/>

                <div className="lop-radio-ellipsis">
                    <span className="lop-radio-mark"></span>
                </div>
                <div className="lop-radio-content">
                    { props.children }
                </div>
            </label>
        </div>
    );
};

export default Radio;