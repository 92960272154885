import React from 'react';
import './content.styles.scss';

interface ContentProps {
    children?: React.ReactNode
}

const Content: React.FC<ContentProps> = ({
    children
}) =>
    <div className="lop-content">
        { children }
    </div>


export default Content;
