import React from 'react';
import './authentication-form.styles.scss';
import { Checkbox } from '../fields';
import { login } from '../../firebase';

interface AuthenticationFormProps {
    onLogin?(idToken: string): any
}

interface AuthenticationFormState {
    screen: number,
    keepSession: boolean,
    username: string,
    password: string,
    email: string,
    loading: boolean,
    mailError: boolean,
    passwordError: boolean,
    errorMessage: string | null
}

const initialState: AuthenticationFormState = {
    screen: 1,
    keepSession: true,
    username: process.env.REACT_APP_DEFAULT_USERNAME || '',
    password: process.env.REACT_APP_DEFAULT_PASSWORD || '',
    email: '',
    loading: false,
    mailError: false,
    passwordError: false,
    errorMessage: null
};

export default class AuthenticationForm extends React.Component<AuthenticationFormProps, AuthenticationFormState> {
    public state: AuthenticationFormState = { ...initialState };

    private handleClickToNav(e: React.MouseEvent, screen: number) {
        e.preventDefault()

        this.setState({ screen })
    }

    private handleChangeKeepSession() {
        this.setState({
            keepSession: !this.state.keepSession
        })
    }

    private handleSubmitLogin(e: React.MouseEvent) {
        e.preventDefault()

        this.setState({
            loading: true,
            mailError: false,
            passwordError: false
        })

        login(this.state.username, this.state.password).then(
            (idToken: any) => this.props.onLogin ? this.props.onLogin(idToken) : false,
            error => {
                const newState = {
                    loading: false,
                    mailError: false,
                    passwordError: false,
                    errorMessage: 'Unknown error'
                }

                switch(error.code) {
                    case 'auth/user-not-found':
                        newState.mailError = true
                        newState.passwordError = false
                        newState.errorMessage = error.message
                        break

                    case 'auth/wrong-password':
                        newState.mailError = false
                        newState.passwordError = true
                        newState.errorMessage = error.message
                        break
                }

                this.setState(newState)
            }
        )
    }

    private handleClickRecoverPassword(e: React.MouseEvent) {
        e.preventDefault()

        this.setState({
            loading: true
        })

        setTimeout(() => {
            this.setState({
                screen: 3,
                loading: false
            })
        }, 1000)
    }

    forms() {
        switch(this.state.screen) {
            case 1:
                return (
                    <form className="lop-form">
                        <h1 className="c-authentication-form-title">Login</h1>
                        <label className={ 'lop-label' + (this.state.mailError ? ' lop-field-error' : '') }>
                            Username
                            <input type="text" className="lop-input" placeholder="Your username"
                                value={ this.state.username }
                                onChange={ e => this.setState({ username: e.target.value }) }
                                disabled={ this.state.loading }/>
                        </label>
                        <label className={ 'lop-label' + (this.state.passwordError ? ' lop-field-error' : '') }> 
                            Password
                            <input type="password" className="lop-input" placeholder="Your password"
                                value={ this.state.password }
                                onChange={ e => this.setState({ password: e.target.value }) }
                                disabled={ this.state.loading }/>

                            <span className="c-authentication-form-help">
                                <a className="lop-link" onClick={ e => this.handleClickToNav(e, 2) }>Forgot password?</a>
                            </span>
                        </label>

                        <Checkbox checked={ Boolean(this.state.keepSession) } onChange={ () => this.handleChangeKeepSession() } disabled={ this.state.loading }>
                            Keep me logged in
                        </Checkbox>

                        <button className="lop-btn" style={ {width: '100%', maxWidth: '224px'} } onClick={ e => this.handleSubmitLogin(e) } disabled={ this.state.loading }>
                            Login
                        </button>
                    </form>
                )

            case 2:
                return (
                    <form className="lop-form">
                        <h1 className="c-authentication-form-title">Password Recovery</h1>
                        <p className="c-authentication-form-text">
                            We’ll send an email to reset your password.
                        </p>
                        <label className="lop-label">
                            Email
                            <input type="email" className="lop-input" placeholder="Your email"
                                value={ this.state.email }
                                onChange={ e => this.setState({ email: e.target.value }) }
                                disabled={ this.state.loading }/>
                        </label>

                        <button className="lop-btn" style={ {width: '100%', maxWidth: '224px'} }
                            onClick={ (e: React.MouseEvent) => this.handleClickRecoverPassword(e) }
                            disabled={ this.state.loading }>
                                Send
                        </button>
                    </form>
                )

            case 3:
                return (
                    <form className="lop-form">
                        <h1 className="c-authentication-form-title">Password Recovery</h1>
                        <p className="c-authentication-form-text">
                            We’ve just sent you an email. You should have received instructions to reset your password.
                        </p>
                        <button className="lop-btn" style={ {width: '100%', maxWidth: '224px'} }
                            onClick={ e => this.handleClickToNav(e, 1) }>
                                Return to sign in
                        </button>
                    </form>
                )
        }
    }

    render() {
        return (
            <div className="c-authentication-form">
                { this.forms() }
            </div>
        )
    }
}