import { createStore, combineReducers } from 'redux';

import languageList from './reducers/languageList.js';
import conflictTypeList from './reducers/conflictTypeList.js';
import regionList from './reducers/regionList.js';
import signeeTypeList from './reducers/signeeTypeList.js';
import statusList from './reducers/statusList.js';
import tagList from './reducers/tagList.js';
import agreement from './reducers/agreement.js';
import paragraphList from './reducers/paragraphList.js';
import signeeList from './reducers/signeeList.js';
import agreementList from './reducers/agreementList.js';
import countryList from './reducers/countryList.js';
import nonStateOrganizationList from './reducers/nonStateOrganizationList.js';
import internationalOrganizationList from './reducers/internationalOrganizationList.js';
import translationList from './reducers/translationList.js';
import locationOfConflictList from './reducers/locationOfConflictList.js';
import typeOfInstrumentList from './reducers/typeOfInstrumentList.js';
import session from './reducers/session.js';

import selectedAgreement from './selected-agreement/selected-agreement.reducer';

const reducer = combineReducers({
    session,
    languageList,
    conflictTypeList,
    regionList,
    signeeTypeList,
    statusList,
    tagList,
    agreement,
    paragraphList,
    signeeList,
    agreementList,
    countryList,
    nonStateOrganizationList,
    internationalOrganizationList,
    translationList,
    locationOfConflictList,
    typeOfInstrumentList,

    selectedAgreement
});

const store = createStore(reducer);

export default store;