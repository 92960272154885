import React, { useEffect } from 'react';
import { logout } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../components/spinner/spinner.component';

const Logout: React.FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
        logout().then(() => {
            navigate('/login');
        })
    }, []);
    
    return <Spinner/>;
};

export default Logout;
