import React from 'react';
import './multitext.styles.scss';

export interface MultitextOption {
    value: any,
    label: string,
    level?: number,
    disabled?: boolean
}

interface MultitextProps {
    onChange?(values: any[]): void,
    onType?(text: string): void,
    values?: any[],
    value?: any,
    options: MultitextOption[],
    filter?: boolean,
    maxValues?: number,
    allowText?: boolean,
    placeholder?: string
}

interface MultitextState {
    input: string,
    displayOptions: boolean,
    dropdownHeight: number,
    focusedTagIndex: number
}

const initialState: MultitextState = {
    input: '',
    displayOptions: false,
    dropdownHeight: 200,
    focusedTagIndex: -1
};

export default class Multitext extends React.Component<MultitextProps, MultitextState> {
    public state: MultitextState = { ...initialState };

    // Global variables
    private maxValues: number = 700;
    private filteredOptions: MultitextOption[] = [];

    // Process and get data
    private getValues(): any[] {
        return this.props.values ? this.props.values : (this.props.value ? [this.props.value] : []);
    }
    private getLabel(value: any): any {
        const { options } = this.props;

        if (typeof value === 'string')
            return value;

        else if (options)
            for (let i = 0; i < options.length; i++)
                if (options[i].value === value)
                    return options[i].label;
    }
    private getOptions(): MultitextOption[] {
        const { options } = this.props;
        const { input } = this.state;

        if (options && options.length) {
            if (input && input.trim().length) {
                return options.filter(option => String(option.label || '').toLowerCase().includes(input.trim().toLowerCase()));
            }
            return options;
        }
        return [];
    }
    private getOptionStyle(option: any) {
        return {
            paddingLeft: (26.5 + (26.5 * option.level)) + 'px'
        }
    }
    private getNextSelectableIndex(index: number): number {
        const options = this.filteredOptions;

        if (options.length && (index > -1) && (index < options.length)) {
            for (let newIndex = index + 1; newIndex < options.length; newIndex++) {
                if (this.optionIsSelectable(options[newIndex])) {
                    return newIndex;
                }
            }
            for (let newIndex = 0; newIndex <= index; newIndex++) {
                if (this.optionIsSelectable(options[newIndex])) {
                    return newIndex;
                }
            }
        }

        return index;
    }
    private getPrevSelectableIndex(index: number): number {
        const options = this.filteredOptions;

        if (options.length && (index > -1) && (index < options.length)) {
            for (let newIndex = index - 1; newIndex > -1; newIndex--) {
                if (this.optionIsSelectable(options[newIndex])) {
                    return newIndex;
                }
            }
            for (let newIndex = options.length - 1; newIndex >= index; newIndex--) {
                if (this.optionIsSelectable(options[newIndex])) {
                    return newIndex;
                }
            }
        }

        return -1;
    }
    private getIdFromLabel(label: string): number | null {
        const { options } = this.props;
        const textToMatch = label.trim().toLowerCase();

        for (const option of options)
            if (option.label.toLowerCase() === textToMatch)
                return option.value;

        return null;
    }

    // Validations
    private isFull() {
        return this.getValues().length >= this.maxValues;
    }
    private optionIsDisabled(option: MultitextOption): boolean {
        return option.disabled === true;
    }
    private optionIsSelected(option: MultitextOption): boolean {
        return this.getValues().indexOf(option.value) > -1;
    }
    private optionIsFocused(index: number): boolean {
        return index === this.state.focusedTagIndex;
    }
    private optionIsSelectable(option: MultitextOption): boolean {
        return (option.disabled !== true) && !this.optionIsSelected(option);
    }

    // Modify data
    private addValue(value: any, keepInput?: any, keepOptions?: any) {
        const values = this.getValues()

        if (values.indexOf(value) === -1)
            this.fireOnChange(
                [...values, value],
                keepInput,
                keepOptions
            )
    }
    private removeValue(value?: any, keepInput?: any, keepOptions?: any) {
        const values = this.getValues()

        if (!values.length)
            return

        if (!value)
            value = values[values.length - 1]

        if (values.indexOf(value) !== -1)
            this.fireOnChange(
                values.filter(v => v !== value),
                keepInput,
                keepOptions
            )
    }

    // UI events
    private handleChange(value: string) {
        if (this.isFull())
            return

        const { onType } = this.props;

        if (onType) onType(value);
        
        this.setState({
            input: value,
            displayOptions: true
        });
    }
    private handleKeyPress(event: any) {
        const key = event.key;

        if (this.isFull())
            return

        const values = this.getValues();
        const { input, focusedTagIndex } = this.state;
        const { allowText } = this.props;
        const options = this.filteredOptions;

        switch(key) {
            case 'Backspace':
                let canEraseTheLastValue = !input && (!!values && values.length)
                if (canEraseTheLastValue) {
                    this.removeValue()
                }
                return

            case 'ArrowDown':
                if (!this.state.displayOptions) {
                    this.setState({
                        focusedTagIndex: -1,
                        displayOptions: true
                    })
                }
                else if (!!options && !!options.length) {
                    // let nextIndex = focusedTagIndex + 1
                    this.setState({
                        // focusedTagIndex: nextIndex >= options.length ? 0 : nextIndex
                        focusedTagIndex: this.getNextSelectableIndex(focusedTagIndex)
                    })
                }
                return

            case 'ArrowUp':
                if (!!options && !!options.length) {
                    // let nextIndex = focusedTagIndex - 1
                    this.setState({
                        // focusedTagIndex: nextIndex < 0 ? options.length - 1 : nextIndex
                        focusedTagIndex: this.getPrevSelectableIndex(focusedTagIndex)
                    })
                }
                return

            case 'Enter':
                if ((focusedTagIndex > -1) && !!options && !!options.length && (focusedTagIndex < options.length) && this.optionIsSelectable(options[focusedTagIndex]))
                    this.addValue(options[focusedTagIndex].value)

                else if (allowText && input.length)
                    this.addValue(this.getIdFromLabel(input) || input)
                
                return

            default:
                return
        }
    }
    private handleFocus() {
        if (this.isFull())
            return

        this.setState({
            displayOptions: true,
            focusedTagIndex: -1
        })
    }
    private handleBlur() {
        if (this.isFull())
            return

        const stateUpdate = {
            displayOptions: false,
            focusedTagIndex: -1
        }

        if (this.props.allowText && this.state.input.length) {
            this.addValue(this.getIdFromLabel(this.state.input) || this.state.input)
        }

        this.setState(stateUpdate)
    }
    private handleClickTag(value: any, e: any) {
        const values = this.getValues()

        this.fireOnChange(
            e.shiftKey ? (
                values.filter(v => value === v)
            ) : (
                values.filter(v => value !== v)
            )
        )
    }
    private fireOnChange(newValues: any[], keepInputValue?: boolean, keepShowingOptions?: boolean) {
        this.setState({
            focusedTagIndex: -1,
            input: keepInputValue ? this.state.input : '',
            displayOptions: !!keepShowingOptions
        })

        if (this.props.onChange)
            this.props.onChange(this.maxValues === 1 ? newValues[0] : newValues)
    }
    private handleSelectOption(option: MultitextOption, event: React.MouseEvent) {
        if (this.isFull() || !this.optionIsSelectable(option)) {
            event.preventDefault();
            return;
        }

        this.addValue(option.value)
    }
    private handleMouseOverOption(focusedTagIndex: number) {
        this.setState({
            focusedTagIndex
        })
    }

    // Render
    private renderValue(value: any) {
        const valueIsId = value.constructor.name === 'Number'

        return (
            <span onClick={ e => this.handleClickTag(value, e) } className={ valueIsId ? 'c-multitag-id-value' : 'c-multitag-label-value' }>
                { this.getLabel(value) }
                <span className="c-multitag-icon">
                    <span className="c-multiutag-icon-times">
                        &times;
                    </span>
                </span>
            </span>
        )
    }
    private renderOptions() {
        // const { dropdownHeight } = this.state;
        // const filteredOptions = this.filteredOptions;
        this.filteredOptions = this.getOptions();

        if (this.filteredOptions.length) {
            const dropDownStyle = {
                maxHeight: this.state.dropdownHeight + 'px'
            }

            return (
                <ul className="c-multitag-options" style={ dropDownStyle }>
                    { this.filteredOptions.map((option, index) =>
                        <li key={ index }>
                            <span
                            style={ this.getOptionStyle(option) }
                            onMouseDown={ e => this.handleSelectOption(option, e) }
                            onMouseOver={ e => this.handleMouseOverOption(index) }
                            className={
                                'c-multitag-option' + (
                                    this.optionIsFocused(index)
                                    ? ' c-multitag-focused'
                                    : ''
                                ) + (
                                    this.optionIsSelected(option)
                                    ? ' c-multitag-selected'
                                    : ''
                                ) + (
                                    this.optionIsDisabled(option)
                                    ? ' c-multitag-disabled'
                                    : ''
                                )
                            }>
                                { option.label }
                            </span>
                        </li>
                    ) }
                </ul>
            )
        }

        // if (filteredOptions && filteredOptions.length) {
        //     const dropDownStyle = {
        //         maxHeight: dropdownHeight + 'px'
        //     }

        //     return (
        //         <ul className="c-multitag-options" style={ dropDownStyle }>
        //             { filteredOptions.map((option: any, index: number) =>
        //                 <li key={ index }>
        //                     <span
        //                     style={ this.getOptionStyle(option) }
        //                     onMouseDown={ e => this.handleSelectOption(option) }
        //                     onMouseOver={ e => this.handleMouseOverOption(index) }
        //                     className={
        //                         'c-multitag-option' + (
        //                             index === this.state.focusedTagIndex
        //                             ? ' c-multitag-focused'
        //                             : ''
        //                         ) + (
        //                             this.isSelected(option)
        //                             ? ' c-multitag-selected'
        //                             : ''
        //                         )
        //                     }>
        //                         { option.label }
        //                     </span>
        //                 </li>
        //             ) }
        //         </ul>
        //     )
        // }
    }

    render() {
        if (this.props.hasOwnProperty('value')) {
            this.maxValues = 1;
        }
        
        const { input, displayOptions } = this.state;
        const { placeholder } = this.props;

        const values = this.getValues();

        return (
            <label className="c-multitag">
                <div className="c-multitag-field">
                    { values.length ?
                        <ul className="c-multitag-selections">
                            { values.map((value, index) => 
                                <li key={ index }>
                                    { this.renderValue(value) }
                                </li>
                            ) }
                        </ul>
                        : ''
                    }
                    { this.isFull()
                        ? ''
                        : (
                            <input
                            type="text"
                            className="c-multitag-input"
                            onKeyDown={ e => this.handleKeyPress(e) }
                            onBlur={ e => this.handleBlur() }
                            onFocus={ e => this.handleFocus() }
                            onChange={ e => this.handleChange(e.target.value) }
                            value={ input }
                            placeholder={ placeholder || '' }/>
                        )
                    }
                    <div className={ 'c-multitag-appearance lop-input' + (this.isFull() ? ' lop-input-readonly' : '')}/>
                </div>
                { displayOptions && this.renderOptions() }
            </label>
        )
    }
}
