import { TYPES } from '../actions'

const defaultState = {
    data: [],
    updatedAt: null
}

export default (state = defaultState, { type, payload }) => {
    switch (type) {
        case TYPES.SET_AGREEMENT_LIST:
            return {
                data: payload,
                updatedAt: Date.now()
            }

        default:
            return state
    }
}