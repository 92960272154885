import React from 'react';
import { connect } from 'react-redux';
import './search-form.styles.scss';
import searchIcon from '../../assets/icons/search-icon@3x.png';
import Multitext from '../multitext/multitext.component';

interface SearchFormProps {
    onSubmit?(e: any, values: any): void,
    locationOfConflictList: any[]
}

interface SearchFormState {
    text: string,
    date: string,
    countryId: number
}

const initialState: SearchFormState = {
    text: '',
    date: '',
    countryId: 0
};

const mapStateToProps = (state: any) => {
    return {
        locationOfConflictList: state.locationOfConflictList.data
    };
}

class SearchForm extends React.PureComponent<SearchFormProps, SearchFormState> {
    public state: SearchFormState = { ...initialState }

    private handleChangeInput(valueName: string, event: any) {
        switch(valueName) {
            case 'text':
                this.setState({ text: String(event.target.value) || '' });
                break;

            case 'date':
                this.setState({ date: String(event.target.value) || '' });
                break;

            case 'countryId':
                this.setState({ countryId: Number(event) || 0 });
                break;
        }
    }

    private handleSubmit(e: any) {
        if (this.props.onSubmit)
            this.props.onSubmit(e, {
                text: this.state.text,
                date: this.state.date,
                countryId: Number(this.state.countryId)
            });
    }

    render() {
        const { locationOfConflictList } = this.props;
        const countryIdIsNotValid = this.state.countryId === 0;
        const withoutCountry = this.state.countryId === -1;

        return (
            <form className="lop-search-form" onSubmit={ e => this.handleSubmit(e) }>
                <div className="search-form-items">

                    <div className="lop-search-form-item lop-search-form-title">
                        <input type="text" className="lop-search-form-input" placeholder="Search for a Publication"
                            value={ this.state.text }
                            onChange={ e => this.handleChangeInput('text', e) }/>
                    </div>

                    <span className="lop-search-form-vertical-rule"/>

                    <div className="lop-search-form-item lop-search-form-country">
                        <Multitext
                            placeholder={ 'Select country' }
                            value={ this.state.countryId || null }
                            options={ locationOfConflictList.map((countryData: any) => ({
                                label: countryData.name,
                                value: countryData.id
                            })) }
                            filter={ true }
                            onChange={ (newValue: any) => this.handleChangeInput('countryId', newValue) }
                            allowText={ true }/>

                        {/* <select
                            className="lop-search-form-input"
                            value={ this.state.countryId }
                            onChange={ e => this.handleChangeInput('countryId', e) }>
                                <optgroup>
                                    <option value="0" disabled={ countryIdIsNotValid }>
                                        { countryIdIsNotValid ? 'Select country...' : '... unselect country' }
                                    </option>
                                    <option value="-1" disabled={ withoutCountry }>
                                        { withoutCountry ? 'Without country...' : '... without country' }
                                    </option>
                                </optgroup>
                                <optgroup>
                                    { locationOfConflictList.map(countryData =>
                                        <option value={ countryData.id } key={ countryData.id }>
                                            { countryData.name }
                                        </option>
                                    ) }
                                </optgroup>
                        </select> */}
                    </div>

                    <span className="lop-search-form-vertical-rule"/>

                    <div className="lop-search-form-item lop-search-form-date">
                        <input type="date" className="lop-search-form-input"
                            value={ this.state.date }
                            onChange={ e => this.handleChangeInput('date', e) }/>
                    </div>
                    
                    <div className="lop-search-form-item lop-search-form-button">
                        <button type="submit" aria-label="Search for a Publication" /*disabled={ (!this.state.text && !this.state.date) }*/>
                            <img src={ searchIcon } alt="Search"/>
                        </button>
                    </div>
                </div>
            </form>
        )
    }
}

export default connect(mapStateToProps, {})(SearchForm);
